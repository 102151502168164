import React from "react";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { API } from '../../config/config.tsx';

import AdminLyout from '../../layout/admin/AdminLayout.tsx';
import { fn_axios } from "../../common/common.tsx";

function UserManageDetail() {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const userEmail = queryParams.get('userEmail');
    const userId = queryParams.get('userId');
    console.log(userId);

    const [userDetailInfo, setUserDetailInfo] = useState(null);
    const [usetAuthList, setUserAuthList] = useState(null);
    //console.log(userEmail);

    const getUserDetail = () => {
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/${API.VERSION}/mng/getUserManageDetail`,
            params: { email: userEmail }
        };
        fn_axios(param, callbackGetUserDetail, null);
    }
    const callbackGetUserDetail = (res) => {
        console.log(res.data.map.userDetailInfo);
        setUserDetailInfo(res.data.map.userDetailInfo);
        console.log(res.data.map.userAuthList);
        setUserAuthList(res.data.map.userAuthList)
    }

    const handleInputChange = (field, value) => {
        setUserDetailInfo({
            ...userDetailInfo, [field]: value
        });
    };

    // const getUserAuthList = () => {
    //     const param = {
    //         method: 'GET',
    //         url: `${API.BACKEND}/${API.VERSION}/mng/getUserAuthManageList`,
    //         params: { email: userEmail }
    //     };
    //     fn_axios(param, callbackGetUserAuthList, null);
    // }
    // const callbackGetUserAuthList = (res) => {
    //     console.log(res);
    //     setUserAuthList(res.data.map.userAuthList);
    // }

    useEffect(() => {
        getUserDetail();
    }, []);

    return (
        <AdminLyout>
            <div className="admin_contents admin_user_detail">
                <span className="admin_user_profile" style={{width:"20%"}}>
                    <img src={userDetailInfo == null ? "" : userDetailInfo.userProfileFileUrl} className="admin_profile_img" />
                </span>
                <span className="admin_detail_content" style={{width:"30%"}}>
                    <h1 className="admin_user_sub_title">사용자 상세 화면</h1>
                    <ul>
                        <li>
                            <p>이름</p>
                            <input type="text" value={userDetailInfo == null ? "" : userDetailInfo.userNm} />
                        </li>
                        <li>
                            <p>이메일</p>
                            <input type="email" value={userDetailInfo == null ? "" : userDetailInfo.userEmail} />
                        </li>
                        <li>
                            <p>전화번호</p>
                            <input type="text" value={userDetailInfo == null ? "" : userDetailInfo.userPhone} />
                        </li>
                        <li>
                            <p>성별</p>
                            <input type="text" value={userDetailInfo == null ? "" : userDetailInfo.userGndr} />
                        </li>
                        <li>
                            <p>소셜 정보</p>
                            <input type="text" value={userDetailInfo == null ? "" : userDetailInfo.codeLNm} />
                        </li>
                        <li>
                            <p>관심 종목</p>
                            <input type="text" value={userDetailInfo == null ? "" : userDetailInfo.gameKindNm} />
                        </li>
                        <li>
                            <p>경기 참가 수</p>
                            <input type="number" value={userDetailInfo == null ? "" : userDetailInfo.enterGameKindNum} />
                        </li>
                        <li>
                            <p>가입일</p>
                            <input type="date" value={userDetailInfo == null ? "" : userDetailInfo.regrDtm} />
                        </li>
                        <li>
                            <p>마지막 로그인 일</p>
                            <input type="date" value={userDetailInfo == null ? "" : userDetailInfo.lastLoginDtm} />
                        </li>
                    </ul>
                </span>
                <span className="admin_contents admin_auth_list"  style={{width:"50%"}}>
                    <h1 className="admin_user_sub_title">사용자 권한 목록</h1>
                    <div className="table_type1" style={{ overflow: "auto" }}>
                        <table style={{ borderCollapse: "collapse", width: "100%", tableLayout: "auto" }}>
                            <thead>
                                <tr>권한</tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>검색된 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </span>
            </div>
        </AdminLyout>
    );
}

export default UserManageDetail;