import React from "react";
import {useState,useEffect} from 'react';
import moment from "moment";

import {fn_axios,fn_post_fileUpload_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';

interface CmptDetailNoticeProp {
  propCmpt: any;
}

const CmptDetailNotice : React.FC<CmptDetailNoticeProp> = ({propCmpt}) => {
  const [cmpt, setCmpt] = useState(propCmpt);
  const [boardList, setBoardList] = useState([]);
  const [board, setBoard] = useState(null);
  const [boardAtchFileList, setBoardAtchFileList] = useState([]);
  const [boardComtList, setBoardComtList] = useState([]);
  const [boardCntnList, setBoardCntnList] = useState([]);
  const [comtCntn, setComtCntn] = useState("");
  const [qustCntn, setQustCntn] = useState("");
  const [cmptQustList, setCmptQustList] = useState([]);
  const [isPopup, setIsPopup] = useState(true);

  const [pageNum, setPageNum] = useState(1);
  const [pageMaxNum, setPageMaxNum] = useState(0);
  const [pageCnt, setPageCnt] = useState(10);
  const [paging, setPaging] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");


  const handleBoardComt = (e) => {
    setComtCntn(e.target.value);
  }
  const handleCmptQust = (e) => {
    setQustCntn(e.target.value);
  }
  const handleIsPopup = () => {
    // TODO : 임시
    if(cmpt.cmptLId = '240821_GAME_KIND_0016_000001_0001') {
      alert('문의는 카카오 채널로 문의 바랍니다. \n 대회+청년주간 축제 문의 : http://pf.kakao.com/_jjUAn \n 참가신청+결제 문의 : http://pf.kakao.com/_pcQfG');
    } else {
      alert("정식 오픈전 문의 사항은 카카오채널( http://pf.kakao.com/_pcQfG )로 문의바랍니다.");
    }
    return ;
    setIsPopup(!isPopup);
  }


  const getBoardPaging = (num) => {
    setBoard(null);
    setBoardComtList(null);
    setBoardAtchFileList(null);
    const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/board/getBoardPaging`,
    params : {    regsCmptLId: cmpt.cmptLId
                  , pageNum : num
                  , searchKeyword : searchKeyword
              }
  }
  fn_axios(param,callbackGetBoardPaging,null);
}



const callbackGetBoardPaging = (res) => {
  console.log(res);
  setBoardList(res.data.object);
    var tempInt  = parseInt(res.data.map.cnt%pageCnt);
    if(tempInt != 0 ) {
        tempInt = parseInt(res.data.map.cnt/pageCnt) + 1;
    } else {
        tempInt = parseInt(res.data.map.cnt/pageCnt);
    }
    settingPasing(tempInt);
}

const getBoard = (num) => {
  setBoardList(null);
  setCmptQustList(null);
  const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/board/getSlctBoard`,
    params : {    regsCmptLId: cmpt.cmptLId
                  , boardId : num
              }
    }
  fn_axios(param,callbackGetBoard,null);
}

const callbackGetBoard = (res) => {
  console.log(res);
  setBoard(res.data.map.board);
  setBoardCntnList(res.data.map.boardCntnList);
  setBoardComtList(res.data.map.boardComtList);
  setBoardAtchFileList(res.data.map.boardAtchFileList);
}


const settingPasing = (maxNum) => {
    setPageMaxNum(parseInt(maxNum));
    console.log(maxNum);
    // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
    var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2)  ;
    var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) +2;
    var tempList = [];

    if(maxNum != 0 ) {
        for (var i = start; i <= end; i++) {
            tempList.push(i);
        }
    }
    console.log(tempList);
    setPaging(tempList);
}
const onClickPaging = (e) => {
  setPageNum(parseInt(e.target.text));
  //getPointPage(e.target.text);

  return null; 
}
const onClickSearch = () => {
  if(pageNum == 1) {
    getBoardPaging(pageNum);
  } else {
    setPageNum(1);
  }
  
}

const handleDownload = async (e) => {
  try {
    const res = await fetch(boardAtchFileList[e.target.id].atchFileUrl);
    const blob = await res.blob();
    const downloadUrl = window.URL.createObjectURL(blob); // 이 과정이 필요하다.

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = boardAtchFileList[e.target.id].atchFileNm;
    link.click()
  } catch (error) {
    console.error('Error downloading file:', error);
  }
  return;
};

const handleSearchKeyword = (e) => {
  setSearchKeyword(e.target.value);
};

const loadingBoardTitle = () => {
  return <tr>
          <td className="tit">{board.boardTitle}</td>
          <td className="date">{moment(board.regrDtm).format('YYYY-MM-DD')}</td>
          <td>{board.slctNum}</td>
        </tr>
}

const loadingBoard = () => {
  // return <div className="accordion" dangerouslySetInnerHTML={board.boardCntn}>
  return (<div>
            <div  className="accordion"  >
              <div dangerouslySetInnerHTML={{ __html: board.boardCntn}}></div>
              {boardCntnList.map((item, index) => (
                <div dangerouslySetInnerHTML={{ __html: item.boardCntn}}></div>
            ))}
            </div>
            <div className="btn_accordion mo">상세내용 더보기</div>
            <div className="file">
              {boardAtchFileList == null ? null : boardAtchFileList.map((item, index) => (
              <a href="#" onClick={handleDownload}><p id={index}>첨부파일 {index}. {item.atchFileNm}</p></a>
              ))}
            </div>
          </div>
  );
}

const loadingBoardComt = () => {
  return (
    <div>
      <div className="comment">
      <div className="comment_input">
        <input type="text" name="comment" placeholder="댓글 입력하기" value={comtCntn} onChange={handleBoardComt} maxLength={100}/>
        <p className="input_bt" >100글자 이내로 입력이 가능합니다.</p>
        <span>({comtCntn.length})</span>
        <button className="btn_type1" onClick={createBoardComt}>등록하기</button>
      </div>
      <div className="comment_view">
        <ul className="accordion">
        {boardComtList == null ? null : boardComtList.map((item, index) => (
          item.regrUserId == getCookieUserId() ? 
            <li className="del" ><span onClick={() => deleteBoardComt(item.comtOrd)}>del</span>{item.comtCntn}</li> : <li>{item.comtCntn}</li>
        ))}
        </ul>
        <div className="btn_accordion">댓글 더보기</div>
      </div>
    </div>
    <div className="paging_arrow">
      <a href="#" onClick={backList}>목록으로 돌아가기</a>
    </div>
  </div>
  );
}

const backList = () => {
  getBoardPaging(pageNum);
  getCmptQust();
}


const deleteBoardComt = (comtOrd) => {
  const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/board/deleteBoardComt`,
    params : {    comtOrd: comtOrd
                  , boardId : board.boardId
              }
    }
  fn_axios(param,callbackDeleteBoardComt,null);
}

const callbackDeleteBoardComt = (res) => {
  console.log(res);
  setBoardComtList(null)
  setBoardComtList(res.data.object);
}

const createBoardComt = () => {
  if(getCookieUserId()) {
    const param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/board/createBoardComt`,
      params : {    regsCmptLId: cmpt.cmptLId
                    , boardId : board.boardId
                    , userId : getCookieUserId()
                    , comtCntn : comtCntn
                }
      }
    fn_axios(param,callbackCreateBoardComt,null);
  } else {
    alert("로그인 후 이용바랍니다.");
    return false;
  }
}

const callbackCreateBoardComt = (res) => {
  console.log(res);
  setBoardComtList(null)
  setBoardComtList(res.data.object);
}

const createCmptQust = () => {
  if(getCookieUserId()) {
    const param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/cmpt/createCmptQust`,
      params : {    cmptLId: cmpt.cmptLId
                    , userId : getCookieUserId()
                    , qustCntn : qustCntn
                }
      }
    fn_axios(param,callbackCreateCmptQust,null);
  } else {
    alert("로그인 후 이용바랍니다.");
    return false;
  }
}

const callbackCreateCmptQust = (res) => {
  console.log(res);
  if(res.status) {
    setCmptQustList(res.data.object)
    setQustCntn(null);
    handleIsPopup();
  } else {
    alert("정상적으로 등록되지 않았습니다.");
  }
}

const getCmptQust = () => {
  const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/cmpt/getCmptQust`,
    params : {    cmptLId: cmpt.cmptLId
                  , userId : getCookieUserId()
              }
    }
  fn_axios(param,callbackGetCmptQust,null);
}

const callbackGetCmptQust = (res) => {
  console.log(res);
  setCmptQustList(res.data.object);
}

useEffect(() => {
  getBoardPaging(pageNum);
  }, [pageNum]); 

useEffect(() => {
  getCmptQust();
  }, []); 

return (
      <div className="info">
        <div className="search_box">
          <input type="text" name="title_search" value={searchKeyword} onChange={handleSearchKeyword} />
          <button className="" onClick={onClickSearch}>제목 검색</button>
          <a href="#" onClick={handleIsPopup}>1:1 문의하기</a>
        </div>

        <div className="qna">
        {cmptQustList == null ? null : cmptQustList.map((item, index) => (
                <div>
                  <div className="question">
                    <span className="tit">질문{index + 1} : </span>
                    <span>
                      {item.qustCntn}
                    </span>
                    <p className="date">{moment(item.qustDtm).format('YYYY-MM-DD')} </p>
                  </div>
                  {item.answCntn == null ? null :
                  <div className="answer">
                    <span className="tit">답변{index + 1}  : </span>
                    {item.answCntn}
                    <p className="date">{moment(item.answDtm).format('YYYY-MM-DD')} </p>
                  </div>
                  }
              </div>
              ))}
        </div>

        <div className="table_type1 default">
          <table>
            <colgroup className="pc">
              <col style={{ width: "auto" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "7%" }} />
            </colgroup>
            <colgroup className="mo">
              <col style={{ width: "auto" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "16%" }} />
            </colgroup>
            <thead>
              <th>제목</th>
              <th>등록일</th>
              <th>조회수</th>
            </thead>
            <tbody>
              {boardList == null  || boardList.length == 0 ? 
              <tr>
                <td colSpan={3}>
                  <h3 style={{textAlign:"center"}}>등록된 알림이 없습니다.</h3>                
                </td>
              </tr>
              : boardList.map((item, index) => (
                  <tr>
                    <td><a href="#" onClick={() => getBoard(item.boardId)}>{item.boardTitle}</a></td>
                    <td className="date">{moment(item.regrDtm).format('YYYY-MM-DD')} </td>
                    <td>{item.slctNum}</td>
                  </tr>
              ))}
              {board == null ? null : loadingBoardTitle()}
            </tbody>
          </table>
          {board == null ? null : loadingBoard()}
        </div>
        {board == null ? null : loadingBoardComt()}
       

        {boardList == null ? null : 
        <div className="paging">
            {paging.map((item, index) => (
                <a href="#" key={item} onClick={onClickPaging} className={pageNum == item ? "on" :""}>{item}</a>
            ))}
        </div>
        }
      
        {/* <!-- popup --> */}
        {isPopup ? null : 
        <div className="dim_layer">
          <div className="popup_layer">
            <div className="btn_close">
              <a href="#" onClick={handleIsPopup}>닫기</a>
            </div>
            <div className="tit"><span className="bt_line">1:1 문의하기</span></div>
            <p>궁금하신 내용을 200자 이내로 작성하여 등록하시면 빠른시간 내에 답변 드리도록 하겠습니다.</p>
            <textarea name="ask" value={qustCntn} maxLength={200} onChange={handleCmptQust} ></textarea>
            <div className="bt_area">
              <span className="num">({qustCntn.length})</span>
              <button className="btn_type1" onClick={createCmptQust}>문의사항 등록하기</button>
            </div>
          </div>
        </div>
        }
        {/* <!-- //popup --> */}
      </div>
  );
}

export default CmptDetailNotice;
