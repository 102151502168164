import React from 'react';
import {useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../layout/Layout.tsx';
import { useNavigate } from 'react-router-dom';

function JoinAgree() {
	const location = useLocation();
	const navigate = useNavigate();
	const { p_userName, p_userPhone,p_userGender,p_userBirthday,p_isAge , p_adertPhone,p_adertBrithday } = location.state || {};
	const [isAgree, setIsAgree] = useState<boolean>(false);
	// const [isAge, setIsAge] = useState<boolean>(false);
	const [isAdvert, setIsAdvert] = useState<boolean>(false);
	const [allAgree, setAllAgree] = useState<boolean>(false);


	const handleIsAgree = (e) => {
		setIsAgree(e.target.value == "true");
	}; 
	// const handleIsAge = (e) => {
	// 	setIsAge(e.target.value == "true");
	// }; 
	const handleIsAdvert = (e) => {
		setIsAdvert(e.target.value == "true");
	}; 
	const handleAllAgree  = (e) => {
		setAllAgree(e.target.value == "true");
		setIsAgree(e.target.value == "true");
		setIsAdvert(e.target.value == "true");
	}; 

	const moveJoin = () => {
		if(!isAgree) {
			alert("필수 동의 항목 동의 바랍니다.");
			return;
		}
		// if(!isAge) {
		// 	alert("현재 14세 미만인 경우 보호자 인증이 필요합니다.");
		// }
		var passParam = {
			p_userName : p_userName,
			p_userPhone : p_userPhone ,
			p_userGender : p_userGender ,
			p_userBirthday : p_userBirthday ,
			p_isAge : p_isAge ,
			p_adertPhone : p_adertPhone ,
			p_adertBrithday : p_adertBrithday 
		};
		navigate(`/user/join?isAgree=${isAgree}&isAdvert=${isAdvert}`, {state : passParam});

		// window.location.href = `/user/join?isAgree=${isAgree}&isAge=${isAge}&isAdvert=${isAdvert}`;
	}


	useEffect(() => {
	  }, [])

	return (
		<Layout>
			
		{/* // <!-- contents --> */}
		<div className="contents join" >
		  <h1>개인정보 수집 및 활용</h1>
		  {/* <!-- login --> */}
		  	<div style={{ margin:"5px", fontSize: "100%"}}>
			  	<section style={{marginLeft:"3px",justifyContent: "left",marginTop:"20px"}}>
					<div>
						<h3 style={{textDecoration:"underline"}}>전체동의</h3><br/>
						<input type="radio" name="allAgree" value="true" checked={allAgree ? true : false} onChange={handleAllAgree}/><label htmlFor="gender_m"> 동의함</label>
						<input type="radio" name="allAgree" value="false" checked={allAgree ? false : true}  onChange={handleAllAgree}/><label htmlFor="gender_f">동의하지않음</label>
					</div>
				</section>
				<section style={{justifyContent: "left",marginTop:"20px"}}>
					<div>
						<div style={{height:"250px", maxHeight: '600px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
						<b>1. 개인정보의 수집 및 이용에 대한 동의</b><br/>
						<b>가. 수집 및 이용 목적</b><br/>
						우리그라운드에서 신청 대행하는 스포츠 대회 신청건에 필요한 사항(대회신청접수 등)에 대하여 대회신청 진행을 위하여 필요한 최소한의 범위 내에서 개인정보를 수집하고 있습니다. <br/>
						<br/>
						<b>나. 수집 및 이용 항목(필수항목)</b><br/>
						1) 성명(한글/영문), 생년월일, 연락처, 전자우편, 주소 등의 기본적인 개인정보<br/>
						2) 관심 종목 내역, 내 기록 관리 등 분야선정 및 활동내역에 관한 일반적인 참여정보<br/>
						<br/>
						<b>다. 개인정보의 보유 및 이용 기간</b><br/>
						신청자의 개인정보 수집ㆍ이용에 관한 동의일로부터 회원 탈퇴시까지 위 이용 목적을 위하여 보유 및 활용하게 됩니다. 단, 대회종료 후에는 기록관리 유지, 민원처리, 분쟁해결 및 법령상 의무이행 등을 위하여 1년간 보유하고 개인적인 설정에 의해 저장 및 삭제 됩니다.<br/>
						<br/>
						<b>라. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익</b><br/>
						위 개인정보 중 필수정보의 수집ㆍ이용에 관한 동의는 대회 진행을 위해 필수적이므로, 위 사항에 동의하셔야만 대회신청 및 대회 참가가 능합니다.<br/>
						<br/>
						<br/>
						<b>2. 민감정보 수집에 대한 동의 (민감정보 기재시에만 한함)</b><br/>
						<br/>
						<b>가. 수집 및 이용 목적 : 우리그라운드에 등록된 기본적인 개인정보(프로필) 및 활동내역 관리</b><br/>
						<br/>
						<b>나. 개인정보 제공항목 : 개인 운동 기록, 장애 관련 사항</b><br/>
						<br/>
						<b>다. 제공정보의 보유 및 이용 기간 : 1년</b><br/>
						<br/>
						<b>라. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익</b><br/>
						신청자는 민감정보 처리에 대한 동의를 거부할 권리가 있습니다. 다만, 신청자가 동의를 거부하는 경우 원활한 대회 진행을 할 수 없어 신청 접수에 제한을 받을 수 있습니다.<br/>
						</div>
						<div>
							<b style={{textDecoration:"underline"}}>우리그라운드가 위와 같이 개인정보를 수집ㆍ이용하는 것에 동의합니다. 비동의시 회원가입 불가능 합니다.</b><br/>
							<br/>
							<input type="radio" name="isAgree" value="true" checked={isAgree ? true : false} onChange={handleIsAgree}/><label htmlFor="gender_m">동의함</label>
							<input type="radio" name="isAgree" value="false" checked={isAgree ? false : true}  onChange={handleIsAgree}/><label htmlFor="gender_f">동의하지않음</label>
						</div>
					</div>
				</section>
				<section style={{justifyContent: "left",marginTop:"20px"}}>
					<p>
						<b>3. 마케팅 정보성 개인정보 수집 동의 (선택)</b><br/>
						<br/>
						우리그라운드가 주최 또는 대행하는 스포츠 대회 정보 및 이벤트 정보 수신에 대한 동의<br/>
						채널 : SMS, 전자우편,  카카오톡채널<br/>
						<br/>
						<input type="radio" name="isAdvert" value="true" checked={isAdvert} onChange={handleIsAdvert}/><label htmlFor="gender_m">동의함</label>
						<input type="radio" name="isAdvert" value="false" checked={!isAdvert}  onChange={handleIsAdvert}/><label htmlFor="gender_f">동의하지않음</label>
					</p>
				</section>
				{/* <section style={{justifyContent: "left",marginTop:"20px"}}>
					<p>
						<b>4. 14세 이상</b><br/>
						우리그라운드는 14세 이하인 경우 회원가입이 제한적일 수 있습니다. 14세 이상입니까?<br/>
						<br/>
						<input type="radio" name="isAge" value="true" checked={isAge} onChange={handleIsAge}/><label htmlFor="gender_m">14세 이상</label>
						<input type="radio" name="isAge" value="false" checked={!isAge}  onChange={handleIsAge}/><label htmlFor="gender_f">14세 미만</label>
					</p>
				</section> */}
			</div>	
		  <div className="t_ct">
			<button className="btn_type1 complete" onClick={moveJoin}>다음 화면으로 이동</button>
		  </div>
	   
		</div>
		</Layout>
	);
  }
		
  export default JoinAgree;