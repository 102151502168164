import React from "react";
import moment from "moment";

import Layout from "../../layout/Layout.tsx";
import Slider from "../../layout/Slider.tsx";

import {fn_axios,fn_post_fileUpload_axios} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';

import {useState,useEffect} from 'react';
import {Link,useLocation } from 'react-router-dom';

function CmptList() {
    const [pageNum, setPageNum] = useState(1);
    const [pageMaxNum, setPageMaxNum] = useState(0);
    const [pageCnt, setPageCnt] = useState(10);
    const [paging, setPaging] = useState([]);
    const [searchMonth, setSearchMonth] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [ordColumn, setOrdColumn] = useState("REGR_DTM");
    const [countVo, setCountVo] = useState(null);
    const [selectedSigngu, setSelectedSigngu] = useState([]);
    const [signguList, setSignguList] = useState([]);
    const [cmptList, setCmptList] = useState([]);
    const [cmptStateDvisCd, setCmptStateDvisCd] = useState([]);
    const [gcVo, setGcVo] = useState(null);
    const [boldClick, setBoldClick] = useState("");

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const [gameKindCd, setGameKindCd] = useState(queryParams.get('gameKindCd'));
    const [gameKindNm, setGameKindNm] = useState(queryParams.get('gameKindNm'));


    const handleSearchKeyword = (e) => {
        setSearchKeyword(e.target.value);
    };
    
    const onClickPaging = (e) => {
        setPageNum(parseInt(e.target.text));
        //getPointPage(e.target.text);

        return null; 
    }
    const clickSearch = () => {
        getCmptPasing(pageNum);
    }

    const getLareaAddr = () => {
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/v1/lareaAddr/getLareaAddr`,
            params: null,
          };
          fn_axios(param, callBackGetLareaAddr, null);
    }
    const callBackGetLareaAddr = (res) => {  
        const resList = res.data.object;
        const tempList =resList.filter(item => (item.signguNm || '').trim() !== "");
    
        // setSignguDataList(resList);
        setSignguList(tempList);
      }

    const handleSignguChange = (e) => {
        setSelectedSigngu(e.target.value);
        return;
    };

    const handleCmptStatus = (e) => {
        setCmptStateDvisCd(e.target.value);
        return;
    };

    const handleMonthClick = (month) => {
        setSearchMonth(month); // 클릭한 월을 searchMonth 상태로 설정
        setPageNum(1); // 페이지를 1로 초기화하여 첫 페이지부터 조회
        getCmptPasing(1); // 대회 조회 함수 호출 
        setBoldClick(month);
    }
    const convertToTwoMonth = (month) => {
        return month < 10 ? '0' + month : '' + month;
    }

    const getCmptPasing = (num) => {
        const param = {
			method : 'GET',
			url : `${API.BACKEND}/${API.VERSION}/cmpt/getCmptPaging`,
			params : {lareaAddrId: selectedSigngu
                    , gameKindCd : gameKindCd
                    , pageNum : num
                    , cmptStateDvisCd : cmptStateDvisCd // CMPT_STATE_DIVS_CD_01(전체), CMPT_STATE_DIVS_CD_02(접수중), CMPT_STATE_DIVS_CD_04(접수마감), 04(대회종료)
                    , searchMonth : searchMonth
                    , searchKeyword : searchKeyword
                    , ordColumn : ordColumn
                }
		}
		fn_axios(param,callbackGetCmptPasing,null);
    }

    const handleInitOnClick = () => {
        setSearchMonth("");
        getCmptPasing(pageNum);
    }

    /* 대회 count 호출 */
    const getGameCount = (pageNum) => {
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/${API.VERSION}/cmpt/getGameCount`,
			params : {
                lareaAddrId: selectedSigngu
                , gameKindCd : gameKindCd
                , cmptStateDvisCd : cmptStateDvisCd // CMPT_STATE_DIVS_CD_01(전체), CMPT_STATE_DIVS_CD_02(접수중), CMPT_STATE_DIVS_CD_04(접수마감), 04(대회종료)
                , searchKeyword : searchKeyword
                , ordColumn : ordColumn
            }            
        }
        fn_axios(param, callBackGetGameCount, null);
    }

    const callBackGetGameCount = (res) => {
        setGcVo(res.data.map.gcVo);
        console.log(res.data.map.gcVo);
    }

    const callbackGetCmptPasing = (res) => {
        setCmptList(res.data.object);
        setCountVo(res.data.map.cntVo);
        var tempInt  = parseInt(res.data.map.cntVo.cnt%pageCnt);
        if(tempInt != 0 ) {
            tempInt = parseInt(res.data.map.cntVo.cnt/pageCnt) + 1;
        } else {
            tempInt = parseInt(res.data.map.cntVo.cnt/pageCnt);
        }
        settingPasing(tempInt);
    }

    const settingPasing = (maxNum) => {
        setPageMaxNum(parseInt(maxNum));
        // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
        var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2)  ;
        var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) +2;
        var tempList = [];

        if(maxNum != 0 ) {
            for (var i = start; i <= end; i++) {
                tempList.push(i);
            }
        }
        setPaging(tempList);
    }

    useEffect(() => {
        if(signguList.length === 0 ) {
            getLareaAddr();

            if(queryParams.get('pageNum') != null && queryParams.get('pageNum') != 'null') {
                setPageNum(parseInt(queryParams.get('pageNum')));
            }
            if(queryParams.get('cmptStateDvisCd') != null && queryParams.get('cmptStateDvisCd') != 'null') {
                setCmptStateDvisCd(queryParams.get('cmptStateDvisCd'));
            }
            if(queryParams.get('searchMonth') != null && queryParams.get('searchMonth') != 'null') {
                setSearchMonth(queryParams.get('searchMonth'));
            }
            if(queryParams.get('searchKeyword') != null && queryParams.get('searchKeyword') != 'null') {
                setSearchKeyword(queryParams.get('searchKeyword'));
            }
            // if(queryParams.get('gameKindCd') != null && queryParams.get('gameKindCd') != 'null') {
            //     setGameKindCd(queryParams.get('gameKindCd'));
            // }
        }
      }, ); 

    useEffect(() => {
    // 이 곳에서 업데이트된 userName 값을 사용할 수 있습니다.
    getCmptPasing(pageNum);
    //console.log("getGameCount  ==> "+getGameCount);
    getGameCount(pageNum);
    setSearchMonth("");
    }, [pageNum]); 

  return (
    <Layout>
        <div className="contents contest">

        <h1 className="sub_title pc">{gameKindNm}대회</h1>
        <h1 className="sub_title mo">대회참가 / {gameKindNm}</h1>

        <Slider></Slider>

        <section className="list_type1">
        {/* <!-- list top search --> */}
        <div className="bar">
            {/*<div className="tx_type03 odr3 ${boldFontClass}" onClick={handleInitOnClick}>등록대회 <b>{gcVo == null ? null : gcVo.cnt}</b>건</div>*/}
            <div className="tx_type03 odr1">
                <span onClick={() => {handleInitOnClick(); setSearchMonth("");}} style={{ fontWeight: boldClick === "" ? "bold" : "normal" }}>등록대회 <b>{gcVo == null ? null : gcVo.cnt}</b>건</span> &nbsp;
                <span onClick={() => handleMonthClick(convertToTwoMonth(gcVo == null ? null : gcVo.m1Nm))} style={{ fontWeight: boldClick === convertToTwoMonth(gcVo == null ? null : gcVo.m1Nm) ? "bold" : "normal" }}>{gcVo == null ? null : gcVo.m1Nm}월 : <b>{gcVo == null ? null : gcVo.m1Count}</b></span> &#47;
                <span onClick={() => handleMonthClick(convertToTwoMonth(gcVo == null ? null : gcVo.m2Nm))} style={{ fontWeight: boldClick === convertToTwoMonth(gcVo == null ? null : gcVo.m2Nm) ? "bold" : "normal" }}>{gcVo == null ? null : gcVo.m2Nm}월 : <b>{gcVo == null ? null : gcVo.m2Count}</b></span> &#47;
                <span onClick={() => handleMonthClick(convertToTwoMonth(gcVo == null ? null : gcVo.m3Nm))} style={{ fontWeight: boldClick === convertToTwoMonth(gcVo == null ? null : gcVo.m3Nm) ? "bold" : "normal" }}>{gcVo == null ? null : gcVo.m3Nm}월 : <b>{gcVo == null ? null : gcVo.m3Count}</b></span>
            </div>
            <div className="search_box odr2">
            <select name="area" value={selectedSigngu} onChange={handleSignguChange}>
                <option value="">지역</option>
                {signguList.map((item, index) => (
                <option key={index} value={item.lareaAddrId} >
                    {item.spSignguNm} {item.signguNm}
                </option>
                ))}
            </select>
            <select name="state" value={cmptStateDvisCd} onChange={handleCmptStatus}>
                <option value={""}>상태</option>
                <option value={"CMPT_STATE_DIVS_CD_01"}>접수전</option>
                <option value={"CMPT_STATE_DIVS_CD_02"}>접수중</option>
                <option value={"CMPT_STATE_DIVS_CD_03"}>접수마감</option>
                <option value={"CMPT_STATE_DIVS_CD_04"}>대회종료</option>
            </select>
            <input type="text" name="keyword"  value={searchKeyword} onChange={handleSearchKeyword} placeholder="키워드"/>
            <button onClick={clickSearch}>검색</button>
            </div>
            <div className="list_date odr4">
                <a href="#" className={ordColumn == "REGR_DTM" ? "active":"" } onClick={() => setOrdColumn("REGR_DTM")}>등록일</a> &#47;
                <a href="#" className={ordColumn == "CMPT_END_DT" ? "active":"" } onClick={() => setOrdColumn("CMPT_END_DT")}>마감일</a> &#47;
                <a href="#" className={ordColumn == "CMPT_L_NM" ? "active":"" } onClick={() => setOrdColumn("CMPT_L_NM")}>이름순</a>
            </div>
        </div>
        {/* <!-- //list top search --> */}
        {/* <!-- list --> */}
        <ul className="list">
            {cmptList.map((item, index) => (
                    <li key={index} onClick={() => window.location.href = `/cmpt/cmptDetail?cmptLId=${item.cmptLId}&gameKindNm=${gameKindNm}&pageNum=${pageNum}&cmptStateDvisCd=${cmptStateDvisCd}&searchMonth=${searchMonth}&searchKeyword=${searchKeyword}&prevCmptLId=${index > 0 ? cmptList[index - 1].cmptLId : null}&prepCmptLId=${index < cmptList.length - 1 ? cmptList[index + 1].cmptLId : null}`} 
                            className={(item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" || item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_04")  ? "end" : null}>
                    {/* <Link to={`/cmpt/cmptDetail?cmptLId=${item.cmptLId}`} className="none" >  */}
                    <b >{item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(item.cmptReqDday) >= 0 ? "D-" + Math.abs(item.cmptReqDday) : "D+" + Math.abs(item.cmptReqDday) ) : 
                                                                (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(item.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
                                                                (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감"))} 
                                                                &nbsp;&nbsp;{item.cmptLNm} </b> 
                    {moment(item.cmptStrDt).format('YYYY-MM-DD')} ~ {moment(item.cmptEndDt).format('YYYY-MM-DD')} / {item.gymNm == null ? "(체육관 정보 없음)" : item.gymNm} 
                    <span>주최 : ({item.cmptLHostNm})</span>
                    {/* </Link> */}
                    </li>
            ))}
        </ul>
        <div className="paging">
            {paging.map((item, index) => (
                <a href="#" key={item} onClick={onClickPaging} className={pageNum == item ? "on" :""}>{item}</a>
            ))}
        </div>
        {/* <!-- //list --> */}
        </section>
        </div>
    </Layout>
    );
  }
  
  export default CmptList;