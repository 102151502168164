import React, { useState , useRef, useEffect} from 'react';



interface KiwoomPayPros {
    param: any;
  }
  
const KiwoomPay : React.FC<KiwoomPayPros> = ({param})=> {
    const formRef = useRef(null);
    const d = new Date();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const date = d.getDate().toString().padStart(2, '0');
    const hour = d.getHours().toString().padStart(2, '0');
    const minute = d.getMinutes().toString().padStart(2, '0');
    const second = d.getSeconds().toString().padStart(2, '0');

  const [formData, setFormData] = useState({
    PAYMETHOD : param.PAYMETHOD != null  ? param.PAYMETHOD : "CARD", // 결제수단
    TYPE : param.TYPE != null  ? param.TYPE : 'M', // 결제방식(P:PC/ M:모바일 /W:웹뷰)
    CPID: param.CPID != null  ? param.CPID : 'CTS18333',  //가맹점ID  (필수) : TEST(CTS18333)
    ORDERNO: param.ORDERNO != null  ? param.ORDERNO : `${d.getFullYear()}${month}${date}${hour}${minute}${second}`, // 결제번호(우리그라운드에서 부여고객이 찾을때 사용)
    PRODUCTTYPE: param.PRODUCTTYPE != null  ? param.PRODUCTTYPE : '1', // 상품구분(1: 디지털, 2: 실물)
    BILLTYPE: param.BILLTYPE != null  ? param.BILLTYPE : '1', //과금 유형(1: 일반)
    AMOUNT: param.AMOUNT != null  ? param.AMOUNT : '1000', // 결제금액
    PRODUCTNAME: param.PRODUCTNAME != null  ? param.PRODUCTNAME : '', //상품명 
    TAXFREECD: param.TAXFREECD != null  ? param.TAXFREECD : '00', // 과세 비과세 여부 (00: 과세, 01: 비과세, 02: 복합과세)
    CPQUOTA: param.CPQUOTA != null  ? param.CPQUOTA : '0', // 최대 할부 개월 수(12입력) 0:2:3:4:5:6:7:8:9:10:11:12
    EMAIL: param.EMAIL != null  ? param.EMAIL : '', // 결제 통보 이메일
    USERID: param.USERID != null  ? param.USERID : '', // 고객ID (TB_USER_M.user_id)
    USERNAME: param.USERNAME != null  ? param.USERNAME : '', // 고객명 (TB_USER_M.user_nm)
    PRODUCTCODE: param.PRODUCTCODE != null  ? param.PRODUCTCODE : 'P0001', // 상품코드 : TB_CMPT_L.CMPT_L_ID 들어갈 예정. 추후 나중에 상품 추가되면 다른거 추가.
    RESERVEDINDEX1: param.RESERVEDINDEX1 != null  ? param.RESERVEDINDEX1 : 'RESERVEDATA1', // 키움페이 예약 항목(현재 사용하지 않는듯?)
    RESERVEDINDEX2: param.RESERVEDINDEX2 != null  ? param.RESERVEDINDEX2 : 'RESERVEDATA2', // 키움페이 예약 항목(현재 사용하지 않는듯?)
    RESERVEDSTRING: param.RESERVEDSTRING != null  ? param.RESERVEDSTRING : 'RESERVESTRING', // 키움페이 예약 항목(현재 사용하지 않는듯?)
    RETURNURL: param.RETURNURL != null  ? param.RETURNURL : '', // 결제 성공 후 이동할 url(새창)
    HOMEURL: param.HOMEURL != null  ? param.HOMEURL : '', //결제 성공 후, 이동할 URL (결제 창에서 이동)
    FAILURL : param.FAILURL != null  ? param.FAILURL : '', // 결제 실패 후 , 확인 버튼 입력 시 이동할 URL 결제 창에서 이동    
    CLOSEURL : param.CLOSEURL != null  ? param.CLOSEURL : '', //결제 창에서 취소 누를 시 이동할 URL 결제 창에서 이동
    DIRECTRESULTFLAG: param.DIRECTRESULTFLAG != null  ? param.DIRECTRESULTFLAG : '', //키움페이 결제 완료 창 없 HOMEURL로 바로 이동
    CARDLIST: param.CARDLIST != null  ? param.CARDLIST : '',
    HIDECARDLIST: param.HIDECARDLIST != null  ? param.HIDECARDLIST : '',
    CARDQUOTA: param.CARDQUOTA != null  ? param.CARDQUOTA : '',
    SKIP_YN: param.SKIP_YN != null  ? param.SKIP_YN : '',
  });

  useEffect(() => {
    fnCheck();
    // fnSubmit();
  }, []);

  const fnSubmit = () => {
    let fileName;
    const UserAgent = navigator.userAgent;

    if (
      UserAgent.match(
        /iPhone|iPod|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
      ) != null ||
      UserAgent.match(/LG|SAMSUNG|Samsung/) != null
    ) {
      fileName = process.env.REACT_APP_KIWOOMPAY_CARD_M_URL;
      // window.KIWOOMPAY = window.open('', 'KIWOOMPAY', 'fullscreen');
      // window.location.href = 'https://ssltest.kiwoompay.co.kr/m/card/DaouCardMng.jsp';
      // window.KIWOOMPAY.focus();
    } else {
      fileName = process.env.REACT_APP_KIWOOMPAY_CARD_URL;
      // window.KIWOOMPAY = window.open('', 'KIWOOMPAY', 'width=579,height=527');
      // window.location.href = 'https://ssltest.kiwoompay.co.kr/card/DaouCardMng.jsp';
      // window.KIWOOMPAY.focus();
    }
    // formRef.current.target = 'KIWOOMPAY';
    formRef.current.action = fileName;
    formRef.current.method = 'post';
    // formRef.current.contentType = 'application/json;charset=EUC-KR';
    formRef.current.charset  = 'UTF-8';
    // formRef.current.acceptCharset = "EUC-KR";
    formRef.current.submit();
    // console.log(formRef.current);
  };


  const fnCheck = () => {

    // 주문번호
    if (trim(formData.ORDERNO) === '' || getByteLen(formData.ORDERNO) > 50) {
      alert(`주문번호 (ORDERNO) 를 입력해주세요. (최대:50byte, 현재:${getByteLen(formData.ORDERNO)})`);
      return;
    }
    // 상품구분
    if (trim(formData.PRODUCTTYPE) === '' || getByteLen(formData.PRODUCTTYPE) > 2) {
      alert(`상품구분 (PRODUCTTYPE) 를 입력해주세요. (최대:2byte, 현재:${getByteLen(formData.PRODUCTTYPE)})`);
      return;
    }
    // 과금유형
    if (trim(formData.BILLTYPE) === '' || getByteLen(formData.BILLTYPE) > 2) {
      alert(`과금유형 (BILLTYPE) 를 입력해주세요. (최대:2byte, 현재:${getByteLen(formData.BILLTYPE)})`);
      return;
    }
    // 결제금액
    if (trim(formData.AMOUNT) === '' || getByteLen(formData.AMOUNT) > 10) {
      alert(`결제금액 (AMOUNT) 를 입력해주세요. (최대:10byte, 현재:${getByteLen(formData.AMOUNT)})`);
      return;
    }
    // 추가 필수 항목이 있다면 계속해서 추가하십시오.
    fnSubmit();
  };

  const trim = (txt) => {
    if( typeof txt !== 'string' ) {
        return txt;
    } else  if(txt != null  ) {
        return txt.replace(/\s/g, '');
    } else {
        return "";
    }
  };

  const getByteLen = (p_val) => {
    let tcount = 0;

    if(p_val == null) {
        return tcount;
    }
    for (let i = 0; i < p_val.length; i++) {
      const onechar = p_val.charAt(i);
      if (escape(onechar).length > 4) tcount += 2;
      else if (onechar !== '\r') tcount++;
    }
    return tcount;
  };

  
  return (
    <div>
    <form ref={formRef}  id="frmConfirm" accept-charset="euc-kr" content='text/html; charset=euc-kr' >
    {/* <!--필수항목--> */}
    <input hidden readOnly name="PAYMETHOD" value={formData.PAYMETHOD}/>
    <input hidden readOnly name="TYPE" value={formData.TYPE}/>
    <input hidden readOnly name="CPID" value={formData.CPID}/>
    <input hidden readOnly name="ORDERNO" value={formData.ORDERNO}/>
    <input hidden readOnly name="PRODUCTTYPE" value={formData.PRODUCTTYPE}/>
    <input hidden readOnly name="BILLTYPE" value={formData.BILLTYPE}/>
    <input hidden readOnly name="AMOUNT" value={formData.AMOUNT}/>
    <input hidden readOnly name="PRODUCTNAME" value={formData.PRODUCTNAME}/>
    <input hidden readOnly name="TAXFREECD" value={formData.TAXFREECD}/>
    <input hidden readOnly name="CPQUOTA" value={formData.CPQUOTA}/>
    <input hidden readOnly name="EMAIL" value={formData.EMAIL}/>
    <input hidden readOnly name="USERID" value={formData.USERID}/>
    <input hidden readOnly name="USERNAME" value={formData.USERNAME}/>
    <input hidden readOnly name="PRODUCTCODE" value={formData.PRODUCTCODE}/>
    <input hidden readOnly name="RESERVEDINDEX1" value={formData.RESERVEDINDEX1}/>
    <input hidden readOnly name="RESERVEDINDEX2" value={formData.RESERVEDINDEX2}/>
    <input hidden readOnly name="RESERVEDSTRING" value={formData.RESERVEDSTRING}/>
    <input hidden readOnly name="RETURNURL" value={formData.RETURNURL}/>
    <input hidden readOnly name="HOMEURL" value={formData.HOMEURL}/>
    <input hidden readOnly name="FAILURL" value={formData.FAILURL}/>
    <input hidden readOnly name="CLOSEURL" value={formData.CLOSEURL}/>
    <input hidden readOnly name="DIRECTRESULTFLAG" value={formData.DIRECTRESULTFLAG}/>
    <input hidden readOnly name="CARDLIST" value={formData.CARDLIST}/>
    <input hidden readOnly name="HIDECARDLIST" value={formData.HIDECARDLIST}/>
    <input hidden readOnly name="CARDQUOTA" value={formData.CARDQUOTA}/>
    <input hidden readOnly name="SKIP_YN" value={formData.SKIP_YN}/>
      <button type="button" onClick={fnSubmit}>
        Submit
      </button>
    </form>
    </div>
  );
}
export default KiwoomPay;