import React from "react";
import {useState,useEffect} from 'react';


import {fn_axios,fn_post_fileUpload_axios,getCookieUserId} from './common.tsx';
import {API} from '../config/config.tsx';

interface PartnerSelectorProps {
  propCmpt: any;
  propCmptGameId: String;
  propEnterReqId : String;
  onSelectedValuesChange: () => void;
}

const PartnerSelector : React.FC<PartnerSelectorProps> = ({ propCmpt,propCmptGameId,propEnterReqId,onSelectedValuesChange })=> {
  const [cmpt, setCmpt] = useState(propCmpt);
  const [cmptGameId, setCmptGameId] = useState(propCmptGameId);
  const [enterReqId] = useState(propEnterReqId);
  const [partnerList, setPartnerList] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  const [prtnUserId, setPrtnUserId] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  


  const updateEnterReqPartner = () => {
      const param = {
        method : 'POST',
        url : `${API.BACKEND}/${API.VERSION}/req/updateEnterReqPartner `,
        params : {    cmptGameId : cmptGameId
                      , cmptLId: cmpt.cmptLId
                      , enterReqId : enterReqId
                      , reqUserId : getCookieUserId()
                      , prtnUserId : prtnUserId
                  }
        }
        fn_axios(param,callbackUpdateEnterReqPartner ,null);
    }
    
  const callbackUpdateEnterReqPartner = (res) => {
    if(res.data.status) {
      onSelectedValuesChange();
      closePopup();
    } else {
      alert(res.data.message);
    }
  }
  const clickClose = () => {
    onSelectedValuesChange();
    // closePopup();
  }

  const closePopup = () => {
    var close = document.getElementById('btnClosePartner');
    if(close) {
      close.click();
    }
  }

  const handleIsSearch = () => {
    setIsSearch(!isSearch);
  }

  const getPartner = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/req/getPartner`,
        params: {searchKeyword : searchKeyword
                , searchColumn : isSearch ? "user_email" : "user_phone"
        },
      };
      fn_axios(param, callbackGetPartner, null);
  }
  const callbackGetPartner = (res) => {  
    setPartnerList(res.data.object);
  }

  const changeSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  }
  
  const checkPartner = (userId) => {
    setPrtnUserId(userId);
  }

  useEffect(() => {
    getPartner();
  }, []); 

  return (
    <div className="dim_layer">
      <div className="popup_layer">
        <div className="btn_close">
          <a id="btnClosePartner" onClick={clickClose}>닫기</a>
          {/* <button id="btnClosePartner" onClick={updateEnterReqPartner}>닫기</button> */}
        </div>
        <div className="tit"><span className="bt_line">파트너 선정하기</span></div>
        <p>전화번호 및 이메일 검색으로 세부종목별 파트너를 선정하신 후 참가신청을 완료하실 수 있습니다.</p>
        <div className="search_box">
          <div>
            <input type="radio" name="search_choice" id="mobile_number" readOnly checked={!isSearch} onClick={handleIsSearch} />
            <label htmlFor="mobile_number">휴대폰 번호</label>
            <input type="radio" name="search_choice" id="email" checked={isSearch} onClick={handleIsSearch}/>
            <label htmlFor="email">이메일 주소</label>
          </div>
          <div>
            <input type="text" value={searchKeyword} onChange={changeSearchKeyword} name="search"/>
            <button className="btn_type3" onClick={getPartner}>검색</button>
          </div>
        </div>
        <div className="table_type1 default">
          <table>
            <colgroup>
              <col style={{width: "20%"}}/>
              <col style={{width: "20%"}}/>
              <col style={{width:"auto"}}/>
              <col style={{width: "10%"}}/>
            </colgroup>
            <tbody>
            {partnerList == null || partnerList.length == 0 ? 
            <tr>
              <td colSpan={4} style={{"textAlign":"center"}}>검색된 사용자가 없습니다. 파트너 선택은 우리그라운드 등록된 사용자만 선택 가능합니다.</td>
            </tr>
             : partnerList.map((item, index) => (
                <tr key={"PAR" + index}>
                <td>{item.userNm.slice(0, -1) + '* '}</td>
                <td>{item.userPhone.slice(0, -4) + '**** '}</td>
                <td>{item.userEmail.replace(/^(.{3})/, '***')}</td>
                <td><input type="checkbox" name="partner_check" onClick={() => checkPartner(item.userId)} checked={item.userId == prtnUserId ? true:false} read-only /></td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <div className="bt_area">
          <span className="num"></span>
          <button className="btn_type1 " onClick={updateEnterReqPartner}>선정완료</button>
        </div>
      </div>
    </div>
  );
}
export default PartnerSelector;