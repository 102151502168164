import React from "react";
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';


import {fn_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";

function CmptReqsPayFail() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cmptLId = queryParams.get('cmptLId');
  const gameKindNm = queryParams.get('gameKindNm');
  const [cmpt, setCmpt] = useState(null);

  const getCmpt = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/cmpt/getCmpt`,
        params: {cmptLId : cmptLId},
      };
      fn_axios(param, callbackGetCmpt, null);
  }
  const callbackGetCmpt = (res) => {  
    // setSignguDataList(resList);
    setCmpt(res.data.map.cmpt[0]);
  }


  const moveCmptReqs = () => {
    window.location.href ="/req/cmptReqs?cmptLId=" +  (cmpt == null ? "" : cmptLId) +"&gameKindNm=" + gameKindNm;
  }



  useEffect(() => {
    if(!getCookieUserId()){
      window.location.href = `/cmpt/cmptDetail?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}`;
    }
    if(cmpt == null) {
      getCmpt();
    }
      
  }, []); 
  return (
  <Layout>

  <div className="contents contest">

    <div className="top_line">
      <h1 className="sub_title pc">{cmpt == null ? null : cmpt.gameKindNm}대회</h1>
      <h1 className="sub_title mo">대회참가 / {cmpt == null ? null : cmpt.gameKindNm}</h1>
      <a href="#" onClick={() => window.location.href = `/cmpt/cmptList`} className="btn_return pc">
        목록으로 돌아가기
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>

    <section className="application payment">
      {/* <!-- menu tab & poster --> */}

      {/* <!-- mo ver title --> */}
      <h2 className="mo"><span>{cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
            </span>&nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
      {/* <!-- //mo ver title --> */}

      <div className="sub_menu">
        <div className="tab">
        <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=main&gameKindNm="+gameKindNm} >대회요강</a>
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=game&gameKindNm="+gameKindNm} >세부종목</a>
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=notice&gameKindNm="+gameKindNm} >알림/문의</a>
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=item&gameKindNm="+gameKindNm} >경품/기념품</a>
        </div>
        <div className="tit">참가신청하기</div>
        <div className="poster">
        <img src={cmpt == null ? null : cmpt.cmptPcAtchFileUrl} alt={cmpt == null ? null : cmpt.cmptPcAtchFileNm} />
        </div>
      </div>
      {/* <!-- //menu tab & poster --> */}

      {/* <!-- content --> */}
      <div className="application_content">
        {/* <!-- pc ver title --> */}
        <div className="title pc"> 
          <h2><span>
          {cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
          </span>
          &nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
          <div className="tx_type04">
            <span>{cmpt == null ? null : cmpt.regrDtm}</span> &#47;
            <span>조회 : {cmpt == null ? null : cmpt.slctNum}</span> &#47;
            <span><b>참가 : {cmpt == null ? 0 : cmpt.cmptEnterPerson}</b></span>  
          </div>
        </div>
        {/* <!-- //pc ver title --> */}

        {/* <!-- content info --> */}
        <div className="info step3">
          {/* <!-- select --> */}
          <div className="search_box">
            <div className="tit">
              <b>결제 실패</b>
            </div>
          </div>
          {/* <!-- //select --> */} 
          

          <h2>결제에 실패했습니다. </h2>
          <button className="btn_application okay" onClick={moveCmptReqs}>결제로 돌아가기</button>

        </div>
        {/* <!-- //content info --> */}
        
      </div>
      {/* <!-- //content --> */}
    </section>
  </div>
  </Layout>
  );
}
export default CmptReqsPayFail;