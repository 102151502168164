import React from 'react';
import {useState,useEffect } from 'react';
import Layout from '../../layout/Layout.tsx';
import '../../assets/css/AgeConfirmButton.css';
import {getCookie,removeCookie} from "../../common/common.tsx";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function PassRetrun() {
    const location = useLocation();
	const navigate = useNavigate();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const isAge = queryParams.get('isAge') == "Y";

	useEffect(() => {
        var paramJson  = getCookie("PASS_RESULT");

        removeCookie("PASS_RESULT");

        var passParam = {
            p_userName : isAge ? paramJson.userName : null ,
            p_userPhone : isAge ? paramJson.userPhone : null,
            p_userGender :isAge ? (paramJson.userGender  == '1' ? 'MAN' : 'WOMAN') : null,
            p_userBirthday : isAge ? paramJson.userBirthday.substr(0,4) + '-'+ paramJson.userBirthday.substr(4,2) + '-'+ paramJson.userBirthday.substr(6,2) : null,
            p_isAge : queryParams.get('isAge'),
            p_adertPhone : !isAge ? paramJson.userPhone : null,
            p_adertBrithday : !isAge ? paramJson.userBirthday.substr(0,4) + '-'+ paramJson.userBirthday.substr(4,2) + '-'+ paramJson.userBirthday.substr(6,2) : null 
        };
        navigate('/user/joinAgree', {state : passParam});
	  }, [])

	return (
		<Layout>
			<div className="container">
				<h1>회원가입 본인 인증 중...</h1>
				
			</div>
		</Layout>
    );
  }
		
  export default PassRetrun;