import React from "react";
import {useState,useEffect} from 'react';
import moment from "moment";
import { useLocation } from 'react-router-dom';


import {fn_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";
import Slider from "../../layout/Slider.tsx";

function SearchList() {

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [searchKeyword, setSearchKeyword] = useState(queryParams.get('searchKeyword'));
  const [completSearchKeyword, setCompletSearchKeyword] = useState(queryParams.get('searchKeyword') != null ? queryParams.get('searchKeyword') : "");

  const [cmptList, setCmptList] = useState([]);
  const [gymList, setGymList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [boardList, setBoardList] = useState([]);

  const [cmptCnt, setCmptCnt] = useState(0);
  const [gymCnt, setGymCnt] = useState(0);
  const [boardCnt, setBoardCnt] = useState(0);
  const [eventCnt, setEventCnt] = useState(0);
  const regex = /^[가-힣a-zA-Z0-9]{2,}$/;

  const getCmptPasing = (keyword) => {
    const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/cmpt/getCmptPaging`,
    params : {
                    pageNum : 1
                  , searchKeyword : keyword
                  , pageCnt : 5
              }
  }
  fn_axios(param,callbackGetCmptPasing,null);
}
const callbackGetCmptPasing = (res) => {
  setCmptList(res.data.object);
  setCmptCnt(res.data.map.cntVo.cnt);
}

const getGymPaging = (keyword) => {
  // setBoard(null);
  const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/gym/getGymPaging`,
    params : {      pageNum : 1
                  , searchKeyword : keyword
                  , pageCnt : 5
              }
    };
  fn_axios(param, callbackGetGymPaging,null);
}
const callbackGetGymPaging = (res) => {
  setGymList(res.data.object);
  setGymCnt(res.data.map.cnt);
}

const getBoardPaging = (keyword) => {
  const param = {
  method : 'GET',
  url : `${API.BACKEND}/${API.VERSION}/board/getBoardPaging`,
  params : {      pageNum : 1
                , searchKeyword : keyword
                , boardDivsCd : 'BOARD_DIVS_CD_01'
                , pageCnt : 3
            }
}
fn_axios(param,callbackGetBoardPaging,null);
}



const callbackGetBoardPaging = (res) => {
  setBoardList(res.data.object);
  setBoardCnt(res.data.map.cnt);
}

const getEventPaging = (keyword) => {
  const param = {
  method : 'GET',
  url : `${API.BACKEND}/${API.VERSION}/board/getEventPaging`,
  params : {      pageNum : 1
                , searchKeyword : keyword
                , pageCnt : 3
            }
}
fn_axios(param,callbackGetEventPaging,null);
}

const callbackGetEventPaging = (res) => {
  setEventList(res.data.object);
  setEventCnt(res.data.map.cnt);
}
const handleSearchKeyword = (e) => {
  setSearchKeyword(e.target.value);
};

const clickSearch = () => {
  if(searchKeyword) {
    if(!regex.test(searchKeyword)) {
      alert("한글, 영어, 숫자 포함 2글자 이상만 조회 가능합니다.");
      return;
    }
    getCmptPasing(searchKeyword);
    getGymPaging(searchKeyword);
    getBoardPaging(searchKeyword);
    getEventPaging(searchKeyword);
    setCompletSearchKeyword(searchKeyword);
  }
}

useEffect(() => {
  clickSearch();
}, []); 

return (
  <Layout>
        {/* <!-- contents --> */}
  <div className="contents search">
   
    <section className="search_section list">
      <div className="search_title">
        <h1 className="sub_title3">Search</h1>
        {/* <!-- pc ver --> */}
        <p className="tx_result pc"><span>"{completSearchKeyword}"</span> 검색결과</p> 
        {/* <!-- //pc ver --> */}
      </div>

      <div className="search_contents">
        {/* <!-- search box --> */}
        <div className="search_box">
          {/* <select name="search_all">
            <option>전체</option>
          </select> */}
          <input type="text" name="keyword" value={searchKeyword}  onChange={handleSearchKeyword} placeholder="키워드 입력" style={{minWidth: '250px'}}/>
          <button className="btn_type3" onClick={clickSearch}>검색</button>
          <p className="tx_result mo"><span>"{completSearchKeyword}"</span> 검색결과</p> 
        </div>
        {/* <!-- //search box --> */}
        {/* <!-- search list --> */}
        <div className="search_list">
          <div className="tit">대회 <b>{cmptCnt}</b>건</div>
          <ul>
            {cmptList == null || cmptList.length == 0 ? 
              <li>
                <b>검색된 결과 없습니다.</b>
              </li>
            :
            cmptList.map((item, index) => (
              <li key={'cmpt' + index} onClick={() => window.location.href = `/cmpt/cmptDetail?cmptLId=${item.cmptLId}`}>
                <b><span>{item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(item.cmptReqDday) >= 0 ? "D-" + Math.abs(item.cmptReqDday) : "D+" + Math.abs(item.cmptReqDday) ) : 
                                                                (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(item.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
                                                                (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감"))} .</span> 
                {item.cmptLNm}</b> /
                {moment(item.cmptStrDt).format('YYYY-MM-DD')} ~ {moment(item.cmptEndDt).format('YYYY-MM-DD')} / {item.gymNm == null ? "(체육관 정보 없음)" : item.gymNm} 
              </li>
            ))}
          </ul>
        </div>
        {/* <!-- //search list --> */}
        {/* <!-- search list --> */}
        <div className="search_list type1">
          <div className="tit">그라운드 <b>{gymCnt}</b>건</div>
          <ul>
          {(gymList == null || gymList.length == 0) ?
                    <li>
                      <b>검색된 결과가 없습니다.</b>
                    </li>
                    : gymList.map((item, index) => (
                    <li key={'gym' + index} onClick={() => window.location.href = `/gym/gymDetail?gymId=${item.gymId}&searchKeyword=${searchKeyword}`}>
                      <b>{item.gymNm}</b>
                      <span className="pc">{item.dtlAddr}</span>
                      <span className="pc">{item.gymCadr == "" ? "-" : item.gymCadr}</span>
                      <span className="mo">{item.dtlAddr}</span>
                    </li>
                    ))}
          </ul>
        </div>
        {/* <!-- //search list --> */}
        {/* <!-- search list --> */}
        <div className="search_list type2">
          <div className="tit">이벤트 <b>{eventCnt}</b>건</div>
          <ul>
            {(eventList == null || eventList.length == 0)  ?
              <li>
                <b>검색된 결과가 없습니다.</b>
              </li>
              : eventList.map((item, index) => (
                <li key={'event' + index}  onClick={() => window.location.href = `/event/eventList?eventLId=${item.boardId}&searchKeyword=${searchKeyword}`}>
                  <b>{item.boardTitle}</b>
                  <span className="date">{moment(item.regrDtm).format('YYYY-MM-DD')}</span>
                  <span>{item.slctNum}</span>
                </li>
              ))}
          </ul>
        </div>
        {/* <!-- //search list --> */}
        {/* <!-- search list --> */}
        <div className="search_list type2">
          <div className="tit">공지사항 <b>{boardCnt}</b>건</div>
          <ul>
            {(boardList == null || boardList.length == 0)  ?
              <li>
                <b>검색된 결과가 없습니다.</b>
              </li>
              : boardList.map((item, index) => (
                <li key={'board' + index}  onClick={() => window.location.href = `/board/boardList?boardId=${item.boardId}&searchKeyword=${searchKeyword}`}>
                  <b>{item.boardTitle}</b>
                  <span className="date">{moment(item.regrDtm).format('YYYY-MM-DD')}</span>
                  <span>{item.slctNum}</span>
                </li>
            ))}
          </ul>
        </div>
        {/* <!-- //search list --> */}
      </div>
    </section>
  </div>
  {/* <!-- //contents --> */}
      </Layout>
  );
}

export default SearchList;
