import React from 'react';
import { Map, MapMarker } from 'react-kakao-maps-sdk';

const KakaoMap = ({ apiKey, latitude, longitube }) => {

    const center = {
        lat: latitude,
        lng: longitube,
    };

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Map center={center} level={3} apiKey={apiKey} style={{ width: '100%', height: '100%' }}>
                <MapMarker position={center} />
            </Map>
        </div>
    );
};

export default KakaoMap;