import React from 'react';
import { useRef } from "react";
import {useState,useEffect } from 'react';
import {fn_axios,fn_post_fileUpload_axios, CONSTANTS} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from '../../layout/Layout.tsx';
import {getAccessToken, isUnderNineteen} from "../../common/common.tsx";
import GameItemProvider from "../../common/GameItemButton.tsx";
import LareaAddr from "../../common/LareaAddr.tsx";

import DaumPostcode from "react-daum-postcode";
import Modal from "react-modal"; // 추가

function UpdateUser() {
	// FORM
	const [userEmail, setUserEmail] = useState("");
	const [userNickname, setUserNickname] = useState("");
	const [userName, setUserName] = useState("");
	const [userPaswword1, setUserPassword1] = useState("");
	const [userPaswword2, setUserPassword2] = useState("");
	const [userProfileFileId, setUserProfileFileId] = useState("");
	const [userPhone, setUserPhone] = useState("");
	const [userAddr, setUserAddr] = useState("");
	const [userDtlAddr, setUserDtlAddr] = useState("");
	const [lareaAddr, setLareaAddr] = useState([]);
	const [userBirth, setUserBirth] = useState("");
	const [gameItems, setGameItems] = useState([]);
	const [userInfo, setUserInfo] = useState(null);
	const [userGndr, setUserGndr] = useState("");
	const [prttPhone, setPrttPhone] = useState("");
    const [profile, setProfile] = useState("");

	//값 체크용
	const [phoneCheckNum, setPhoneCheckNum] = useState("");
	const [checkNum, setCheckNum] = useState("");
	const [checkTimer, setCheckTimer] = useState<number>(0);
	const [phoneReadonly, setPhoneReadonly] = useState<boolean>(false);
	const [isTimer, setIsTimer] = useState<boolean>(true);
	const [profileImage, setProfileImage] = useState("");
	const [isOpen, setIsOpen] = useState<boolean>(false); //추가
	const [isInit, setIsInit] = useState<boolean>(true); //추가


    const [userGameItems, setUserGameItems] = useState([]);



	const divPhoneCheckRef = useRef(null);
	const timerRef = useRef(null);



    const getUser = () => {
		const param = {
			method : 'GET',
			url : `${API.BACKEND}/v1/user/getUser`,
			params : {userId:null}
		}
		fn_axios(param,callbackGetUser,null);
	};

    const callbackGetUser = (res) => {
        const userVo = res.data.object;
        setUserEmail(userVo.userEmail);
        setUserNickname(userVo.userNickname);
        setUserName(userVo.userNm);
        setUserProfileFileId(userVo.userProfileFileId);
        setUserPhone(userVo.userPhone);
        setUserAddr(userVo.userAddr);
        setUserDtlAddr(userVo.userDtlAddr);
        setUserBirth(userVo.userBirth.substring(0,4) + "-" + userVo.userBirth.substring(4,6) + "-" + userVo.userBirth.substring(6,8));
        setUserGndr(userVo.userGndr);
        setPrttPhone(userVo.prttPhone);
        document.querySelector('#adertPhone').value = userVo.prttPhone;
        setProfileImage(userVo.userProfileFileUrl);
        setUserInfo(userVo);
    }
    
	const getUserItrstItem = () => {
		const param = {
			method : 'GET',
			url : `${API.BACKEND}/v1/user/getUserItrstItem`,
			params : {userId:null}
		}
		fn_axios(param,callbackGetUserItrstItem,null);
	};

    const callbackGetUserItrstItem = (res) => {
        var testlist= [];
        res.data.object.map(( userGameItem, index) => (
            // setGameItems([...gameItems ,userGameItem.gameKindCd]) 
            testlist.push(userGameItem.gameKindCd) 
        ));
        if(gameItems == null || gameItems.length == 0) {
            setGameItems(testlist);
        }
        setUserGameItems(res.data.object);
    }

    const getUserItrstLarea = () => {
		const param = {
			method : 'GET',
			url : `${API.BACKEND}/v1/user/getUserItrstLarea`,
			params : {userId:null}
		}
		fn_axios(param,callbackGetUserItrstLarea,null);
	};

    const callbackGetUserItrstLarea = (res) => {
        var testlist= [];
        res.data.object.map(( userLareaAddr, index) => (
            // setGameItems([...gameItems ,userGameItem.gameKindCd]) 
            testlist.push(userLareaAddr.lareaAddrId) 
        ));
        if(lareaAddr == null || lareaAddr.length == 0) {
            setLareaAddr(testlist);
        }
    }
    

    

	//값 유효 체크가 필요한 경우.
	const [checkMap,setCheckMap] = useState({ "userEmail":true
											, "userNickname": true
											, "password": true
											, "userPhone": true
											, "userProfileFileId": true
											, "userAddr": true
											, "userDtlAddr": true});


	

	const handleUserNickname = (e) => {
		setUserNickname(e.target.value);
	}; 
	const handleUserName = (e) => {
		setUserName(e.target.value);
	}; 
	const handleUserPaswword1 = (e) => {
		if(userInfo != null && userInfo.loginDivsCd == CONSTANTS.LOGIN_DIVS_CD_01)
			setUserPassword1(e.target.value);
	}; 
	const handleUserPaswword2 = (e) => {
		if(userInfo != null && userInfo.loginDivsCd == CONSTANTS.LOGIN_DIVS_CD_01)
			setUserPassword2(e.target.value);
	}; 
	const handleUserPhone = (e) => {
		setCheckMap({...checkMap,["userPhone"]: false});
		setUserPhone(e.target.value);
	}; 
	const handlePhoneCheckNum = (e) => {
		setPhoneCheckNum(e.target.value);
	}; 
	const handleUserDtlAddr = (e) => {
		setUserDtlAddr(e.target.value);
		if(e.target.value !== null) {
			setCheckMap({...checkMap,["userDtlAddr"]: true});
		}
	}; 
	const handleUserAddr = (e) => {
		setCheckMap({...checkMap,["userAddr"]: false});
		setUserAddr(e.target.value);
	}; 
	const handleUserGndr = (e) => {
		setUserGndr(e.target.value);
	}; 
	const handleProfile = (e) => {
		setProfile(e.target.files);
		sendFile(e.target.files);
	}; 
	const handleUserBirth = (e) => {
		setUserBirth(e.target.value);
	}

	const checkNickname = () => {
		const nicknameRegEx = /^[가-힣a-zA-Z0-9]+$/;
		const nicknameRegEx2 = /^[0-9]+$/;
		setCheckMap({...checkMap,["userNickname"]: false});

		if(userNickname.length < 2 ) {
			alert("두글자 이상 입력바랍니다.");
			return false;
		} else if(userNickname.substring(0,1).match(nicknameRegEx2) !== null) {
			alert("닉네임은 영어 혹은 한글로 시작해야 합니다.");
			return false;
		} else if(userNickname.match(nicknameRegEx) === null) {
			alert("닉네임은 영어, 숫자, 한글만 사용 가능합니다.");
			return false;
		}

		return true;
		
	}

	const checkPassword = () => {
		if(userInfo != null && userInfo.loginDivsCd == CONSTANTS.LOGIN_DIVS_CD_01) {
			const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
			setCheckMap({...checkMap,["password"]: false});

			if(userPaswword1 !== userPaswword2) {
				alert("입력한 비밀번호가 일치하지 않습니다.");
				return false;
			} else if(userPaswword1.match(passwordRegEx) === null) {
				alert("숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요");
				return false;
			} else {
				setCheckMap({...checkMap,["password"]: true});
				return true;
			}
		}
		
		
		
	}

	const onCheckNickname = () => {
		if(!checkNickname()) {
			return;
		}
		const param = {
			method : 'GET',
			url : `${API.BACKEND}/v1/user/checkNickname`,
			params : {userNickname: userNickname}
		}
		fn_axios(param,callBackOnCheckNickname,null);
	};

	const callBackOnCheckNickname = (res) => {
		if(!res.data.status) {
			alert(res.data.message);
			setCheckMap({...checkMap,["userNickname"]: false});
		} else {
			alert(res.data.message);
			setCheckMap({...checkMap,["userNickname"]: true});
		}
	}

	const sendFile = (file) => {
		if((!"image/png".match(file[0].type)) &&
			(!"image/jpeg".match(file[0].type)) &&
			(!"image/jpg".match(file[0].type))
		    ) {
			alert(".png, .jpeg, .jpg 파일만 업로드 가능합니다.");
			return false;
		}
		fn_post_fileUpload_axios(file,fileTestCallBack,null);
	}

	const fileTestCallBack = (res) => {
		if(res.data.status) {
			alert("파일이 정상적으로 업로드 되었습니다.");
			setProfileImage(res.data.object[0].atchFileUrl);
			setUserProfileFileId(res.data.object[0].atchFileId);
			setCheckMap({...checkMap,["userProfileFileId"]: true });
		} else {
			alert("파일 업로드에 실했습니다.");
			setCheckMap({...checkMap,["userProfileFileId"]: false});
		}
		
		
	}

	const sendPhoneVerification = () => {
		const phoneRegEx = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;

		if(userPhone.match(phoneRegEx) === null) {
			alert("올바른 전화번호 형식이 아닙니다. \n '-'을 제외한 전화번호를 입력하세요.");
			return false;
		}
		const param = {
			method : 'POST',
			url : `${API.BACKEND}/v1/sms/sendPhoneVerification`,
			params : {userPhone: userPhone}
		}
		fn_axios(param,callbackSendPhoneVerification,null);

	}

	const callbackSendPhoneVerification = (res) => {
		setCheckNum(res.data.object);
		if(res.data.status) {
			alert(res.data.message);
			divPhoneCheckRef.current.style.setProperty('display', 'block');
			setIsTimer(false);
			CheckingTimer();
		} else {
			alert(res.data.message);
		}
		
		
	}

	const CheckingTimer = () => {
		setCheckTimer(300);
	}
	useEffect(() => {
		timerRef.current = setInterval(() => {
			setCheckTimer(checkTimer => checkTimer - 1);
		}, 1000);

		// 타이머 멈추기
		if(checkTimer <= 0){
			clearInterval(timerRef.current);
		};

		return () => clearInterval(timerRef.current);
		
	});

	const chechPhone = () => {

		// if(checkMap["userPhone"]) {
		// 	alert("인증이 완료되었습니다.");
		// 	return ;
		// }
		if(checkTimer <= 0) {
			alert("인증 시간을 초과하였습니다.");
			return;
		}

		
		if(checkNum === phoneCheckNum) {
			
			alert("전화 인증이 완료되었습니다.");
			setPhoneReadonly(true);
			setCheckMap({...checkMap,["userPhone"]: true});
			setIsTimer(true);
			setCheckTimer(0);

		} else {
			alert("인증번호가 일치 하지 않습니다.");
		}
	}

	const clickDaumAddr = () => {
		setIsOpen(!isOpen); //추가
	}

	const completeDaumAddr = (data:any) =>{
        // setZipcode(data.zonecode);
        setUserAddr(data.roadAddress);
        setIsOpen(false); //추가
		if(data.roadAddress !== null) {
			setCheckMap({...checkMap,["userAddr"]: true});
		}
    }

	const onSelectGameItemChange = (selectedValues) => {
		setGameItems(selectedValues);
	}

	const onSelectedLareaAddr =(selectedValues) => {
		setLareaAddr(selectedValues);

	}

	const clickJoin = () => {
		var tempPrttPhone = "";
		if((userPaswword1 != "" || userPaswword2 != "") && !checkPassword()) {
			return;
		}
		if(!userNickname) {
			alert("닉네임을 입력해주세요.");
			return ;
		}
		if(!checkMap.userNickname) {
			alert("닉네임이 올바르게 입력되지 않았습니다.");
			return ;
		}
		if(!userPhone) {
			alert("휴대폰 번호를 입력해주세요.");
			return ;
		}
		if(!checkMap.userPhone) {
			alert("전화 인증이 완료되지 않았습니다.");
			return ;
		}
		if(!userAddr) {
			alert("주소를 입력해주세요.");
			return ;
		}
		if(!checkMap.userAddr) {
			alert("잘못된 주소입니다.");
			return ;
		}
		if(!userDtlAddr) {
			alert("상세 주소를 입력해주세요.");
			return ;
		}
		if(lareaAddr == null || lareaAddr.length == 0) {
			alert("관심지역을 선택해주세요.");
			return; 
		}
		if(gameItems === null || gameItems.length === 0) {
			alert("하나 이상의 관심 종목을 선택해주세요.");
			return ;
		}
		if(document.querySelector('#adertPhone') != null 
			&& document.querySelector('#isPass') !=null 
			&& document.querySelector('#isPass').value == "true"
			) {
			if(document.querySelector('#adertBrithday') == null || isUnderNineteen(document.querySelector('#adertBrithday').value)){
				alert("보호자는 19세 이상이여야 합니다.");
				return ;
			}
			tempPrttPhone  = document.querySelector('#adertPhone').value;
		}


		const param = {
			method : 'POST',
			url : `${API.BACKEND}/v1/user/updateUser`,
			params : {userNickname : userNickname
					, userId : userInfo.userId
					, userPwd : userPaswword1
					, userProfileFileId : userProfileFileId
					, userPhone : userPhone
					// , userGndr : userGndr
					, userAddr : userAddr
					, userDtlAddr : userDtlAddr
					, lareaAddrList : lareaAddr
					, gameItems : gameItems
					, prttPhone : tempPrttPhone
					// , userNm : userName
					// , userBirth : userBirth
				}
		}
		fn_axios(param,callbackJoin,null);
	}
	const openPassAuth = () => {
		window.MOBILEOK.process(`${API.BACKEND}/v1/pass/request`, "WB", "callBackFunc");
	}

	useEffect(() => {
		const openPassAuthCallback = () => {
			const script = document.createElement('script');
			var funcScript = "function callBackFunc(result) {" +
				"try {" +
					// "document.querySelector('#result').value = JSON.parse(result, null, 4);" +
					"document.querySelector('#adertPhone').value = JSON.parse(result, null, 4).userPhone;" +
					"document.querySelector('#adertBrithday').value = JSON.parse(result, null, 4).userBirthday.substr(0,4) + '-'+ JSON.parse(result, null, 4).userBirthday.substr(4,2) + '-'+ JSON.parse(result, null, 4).userBirthday.substr(6,2);" +
					"document.querySelector('#isPass').value = 'true';" +
				"} catch (error) {" +
					"console.log(error);" +
				"}" +
				"}";
			const callBackFunc = document.createTextNode(funcScript);

			script.appendChild(callBackFunc);
			document.body.appendChild(script);
		}
		openPassAuthCallback();
	}, []); // useEffect가 마운트될 때 한 번만 실행되도록 빈 배열을 두 번째 매개변수로 전달

	const callbackJoin = (res) => {
		alert(res.data.message);
        if(res.data.status) {
            window.location.href = "/user/mypage";
        }
		
	}

    // 유저 정보 호출.
    if(isInit) {
        setIsInit(false);
        getUser();
        getUserItrstItem();
		getUserItrstLarea();
    }

	useEffect(() => {
		//로그인중이면 홈화면으로 이동.
		if(getAccessToken() == null ) {
			window.location.href = "/";
		}
	  }, [])

	return (
		<Layout>
			
		{/* // <!-- contents --> */}
		<div className="contents join">
		  <h1>회원정보 수정</h1>
		  {/* <!-- login --> */}
		  <section className="login_info">
			<div className="title">로그인 정보</div>
			<div className="form">
			  <div className="id">
				<input type="text" value={userEmail} readOnly={true} placeholder="이메일"/>
			  </div>
			  <div className="nickname">
				<input type="text" value={userNickname} onChange={handleUserNickname} placeholder="닉네임"/>
				<label htmlFor="nickname" onClick={onCheckNickname}>중복확인</label>
				<p>닉네임는 영문, 한글, 숫자 혼합 20자리 가능하며 반드시 영문 혹은 한글로 시작되어야 합니다.</p>
			  </div>
			  <div className="password">
				<input type="password" value={(userInfo == null || userInfo.loginDivsCd != CONSTANTS.LOGIN_DIVS_CD_01) ? null : userPaswword1} onChange={handleUserPaswword1} placeholder="비밀번호" readOnly={(userInfo == null || userInfo.loginDivsCd != CONSTANTS.LOGIN_DIVS_CD_01)}/>
				<input type="password" value={(userInfo == null || userInfo.loginDivsCd != CONSTANTS.LOGIN_DIVS_CD_01) ? null : userPaswword2} onChange={handleUserPaswword2} onBlur={checkPassword} placeholder="비밀번호 확인" readOnly={(userInfo == null || userInfo.loginDivsCd != CONSTANTS.LOGIN_DIVS_CD_01)}/>
				<p>{(userInfo == null || userInfo.loginDivsCd == CONSTANTS.LOGIN_DIVS_CD_01) ? "비밀번호는 최소 8글자 이상어야 하며 영문, 숫자 및 특수문자가 반드시 포함되어야 합니다." : "소셜 간편 회원가입 회원은 비밀번호 변경이 불가능합니다."}</p>
			  </div>
			</div>
		  </section>
		  {/* <!-- profile --> */}
		  <section className="profile_info">
			<div className="title">프로필 정보</div>
			<div className="form">
			  <dl className="photo">
				<dt><img src={profileImage} alt="profile"/></dt>
				<dd>
				  <b>프로필 사진</b> <br/> 
				  가로크기 : 1,000 픽셀 <br/>
				  세로크기 : 1,000 픽셀 <br/>
				  파일형식 : JPG / PNG
				  <label htmlFor="file">
					<div className="btn_type1" >이미지 파일 등록</div>
				  </label>
				  <React.Fragment>
				  	<input type="file" id="file" onChange={handleProfile} style={{display:"none"}} multiple={true}/>
				  </React.Fragment>
				</dd>
			  </dl>
			  <div className="name">
				<input type="text" name="name" value={userName} readOnly placeholder="이름"/>
				<div className="gender">
				  <span>성별 : </span>
				  <input type="radio" name="gender" value="MAN" checked={userGndr == "MAN" ? true : false} required /><label htmlFor="gender_m">남</label>
				  <input type="radio" name="gender" value="WOMAN" checked={userGndr == "WOMAN" ? true : false} required  /><label htmlFor="gender_f">여</label>
				</div>
			  </div>
			  <div>
				<input type="date" name="birthday" value={userBirth} readOnly required aria-required="true" data-placeholder="생년월일" max="9999-12-31" /*maxlength={10}*/ />
				{/* <label htmlFor="birthday">날짜선택</label> */}
			  </div>
			  {prttPhone == null ? null 
			  :
				<div>
					<input type="text" id="adertPhone"  readOnly  placeholder="보호자 전화번호"/>
					<label htmlFor="phone" onClick={openPassAuth}>보호자 인증</label>
					<input id="adertBrithday" type="text" name="name" readOnly hidden/>
					<input id="isPass" type="text" name="name" readOnly hidden/>
				</div>
			  }
			  <div>
				<input type="text" value={userPhone} onChange={handleUserPhone} readOnly={phoneReadonly}  placeholder="휴대폰 번호"/>
				<label htmlFor="phone" onClick={sendPhoneVerification}>전화인증</label>
			  </div>
			  <div ref={divPhoneCheckRef} style={{display:'none'}}>
				<input type="text" value={phoneCheckNum} onChange={handlePhoneCheckNum} readOnly={phoneReadonly} placeholder="인증번호"/>
				<label htmlFor="phone"  onClick={chechPhone}>인증 확인</label>
			  </div>
			  <div>
					<p hidden={isTimer}>인증번호 유효 시간 : {Math.floor(checkTimer/60)} : {(checkTimer%60).toString().padStart(2,"0")}</p>
				</div>
			  <div className="address">
				<input type="text" name="address" value={userAddr} onChange={handleUserAddr} readOnly={true} placeholder="주소"/>
				<label htmlFor="address1" onClick={clickDaumAddr}>주소찾기</label>
				<Modal isOpen={isOpen} ariaHideApp={false} >
					<DaumPostcode onComplete={completeDaumAddr} /*height="100%"*/ />
				</Modal>
				<input type="text" name="address" value={userDtlAddr} onChange={handleUserDtlAddr} placeholder="상세주소 입력"/>
			  </div>
			</div>
		  </section>
		  {/* <!-- wish --> */}
		  <section className="area_event" defaultValue={""}>
			<div className="title">관심지역 및 종목</div>
			<div className="form">
				{(lareaAddr != null) ? <LareaAddr onSelectedLareaAddr={onSelectedLareaAddr} userSelectLareaAddr={lareaAddr}></LareaAddr> : null}
			  <div className="event">
				<p>관심종목 1개 이상 5개 이하 필수선택</p>
				{(gameItems != null) ? <GameItemProvider onSelectedValuesChange={onSelectGameItemChange}  userSelectItem={gameItems}></GameItemProvider> : null}
			  </div>
			</div>
		  </section>
	  
		  <div className="t_ct">
			<button className="btn_type1 complete" onClick={clickJoin}>회원 정보 수정</button>
		  </div>
	   
		  <div className="tx_type02 pc"><b>당신이 원하는 대회를 원하는 장소에서</b>당신의 대회를 알리고 싶다면, <b>WOORI GROUND!</b> WOOG ME! CONTACT ME!</div>
		  
		</div>
		</Layout>
	);
  }
		
  export default UpdateUser;