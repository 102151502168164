import React from "react";
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';


import {fn_axios,fn_post_fileUpload_axios,getCookieUserId} from './common.tsx';
import {API} from '../config/config.tsx';

interface TeamSelectorProps {
  propCmpt: any;
  propCmptGameId: String;
  propEnterReqId : String;
  propTeamNo : String;
  onSelectedValuesChange: () => void;
}

const TeamSelector : React.FC<TeamSelectorProps> = ({ propCmpt,propCmptGameId,propEnterReqId,propTeamNo,onSelectedValuesChange })=> {
  const [cmpt, setCmpt] = useState(propCmpt);
  const [cmptGameId, setCmptGameId] = useState(propCmptGameId);
  const [enterReqId] = useState(propEnterReqId);
  const [teamList, setTeamList] = useState([]);
  const [enterTeamNo, setEnterTeamNo] = useState(propTeamNo);
  const [enterTeamNm, setEnterTeamNm] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [newTeam, setNewTeam] = useState("");

  


  const updateEnterReqPartnerTeam = () => {
    if(!enterTeamNo) {
      alert("선택된 팀이 없습니다.");
      return false;
    }
    if(enterTeamNo === propTeamNo) {
      onSelectedValuesChange();
      closePopup();
    }
      const param = {
        method : 'POST',
        url : `${API.BACKEND}/${API.VERSION}/req/updateEnterReqTeam`,
        params : {    cmptGameId : cmptGameId
                      , cmptLId: cmpt.cmptLId
                      , enterReqId: enterReqId
                      , reqUserId : getCookieUserId()
                      , enterTeamNo : enterTeamNo
                      , enterTeamNm : enterTeamNm
                  }
        }
        fn_axios(param,callbackUpdateEnterReqPartnerTeam,null);
    }
    
  const callbackUpdateEnterReqPartnerTeam = (res) => {
    if(res.data.status) {
      onSelectedValuesChange();
      closePopup();
    } else {
      alert(res.data.message);
    }
  }
  const clickClose = () => {
    onSelectedValuesChange();
    // closePopup();
  }

  const closePopup = () => {
    var close = document.getElementById('btnCloseTeam');
    if(close) {
      close.click();
    }
  }

  const changeNewTeam = (e) => {
    setNewTeam(e.target.value);
  }


  const getTeam = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/req/getTeam`,
        params: {cmptLId: cmpt.cmptLId
          , enterTeamNm : searchKeyword
        },
      };
      fn_axios(param, callbackGetPartner, null);
  }
  const callbackGetPartner = (res) => {  
    setTeamList(res.data.object);
  }

  const checkTeam = (teamNo, teamNm)  => {
    setEnterTeamNm(teamNm);
    setEnterTeamNo(teamNo);
  }

  const changeSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  }
  

  const createTeam = () => {
    const koreanRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    const englishNumberRegex = /[a-zA-Z0-9]/;

    // 한글 한 글자 이상
    if(newTeam.length == 0 ) {
      alert("팀명을 입력 바랍니다.");
      return;
    } else if ( newTeam.trim().length == 0) {
      alert("공백 문자만 입력 불가능합니다.");
      return;
    }else if (koreanRegex.test(newTeam) && newTeam.length < 1) {
      alert("한글 한글자 이상만 가능합니다.");
      return ;
    } else if (englishNumberRegex.test(newTeam) && newTeam.length < 2) {
      alert("영문, 숫자 두 글자 이상 입력해야됩니다.");
      return ;
    } 
    const param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/req/createTeam`,
      params : {    cmptGameId : cmptGameId
                    , cmptLId: cmpt.cmptLId
                    , enterReqId: enterReqId
                    , reqUserId : getCookieUserId()
                    // , enterTeamNo : enterTeamNo
                    , enterTeamNm : newTeam.trim()
                }
      }
      fn_axios(param,callbackCreateTeam,null);
  }
  
const callbackCreateTeam = (res) => {
  if(res.data.status) {
    onSelectedValuesChange(res);
    closePopup();
  } else {
    alert(res.data.message);
  }
}

  useEffect(() => {
    getTeam();
  }, []); 

  return (
    <div className="dim_layer">
      <div className="popup_layer">
        <div className="btn_close">
          <a href="#" id="btnCloseTeam" onClick={clickClose}>닫기</a>
        </div>
        <div className="tit"><span className="bt_line">팀 선정하기</span></div>
        <p>팀명 검색으로 세부종목별 팀를 선정하신 후 참가신청을 완료하실 수 있습니다.</p>
        <div className="search_box">
          <div>
            <input type="radio" name="search_choice" id="mobile_number" readOnly checked />
            <label htmlFor="mobile_number">팀명</label>
          </div>
          <div>
            <input type="text" value={searchKeyword} onChange={changeSearchKeyword} name="search"/>
            <button className="btn_type3" onClick={getTeam}>검색</button>
          </div>
        </div>
        <div className="table_type1 default">
          <table>
            <colgroup>
              <col style={{width: "20%"}}/>
              <col style={{width: "20%"}}/>
              <col style={{width:"auto"}}/>
              <col style={{width: "10%"}}/>
            </colgroup>
            <tbody>
            {teamList == null ? null : teamList.map((item, index) => (
                <tr key={"PAR" + index}>
                <td>{item.enterTeamNo}</td>
                <td>{item.enterTeamNm}</td>
                <td>{item.temaUserList}</td>
                <td><input type="checkbox" name="partner_check" onClick={() => checkTeam(item.enterTeamNo, item.enterTeamNm)} checked={item.enterTeamNo == enterTeamNo ? true:false} /></td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <div className="search_box" style={{width:"100%"}}>
            <div style={{width:"100%"}}>팀 생성 :  &nbsp; &nbsp;
              <input type="text" style={{width:"60%"}} value={newTeam} onChange={changeNewTeam} placeholder="팀명 입력"  />
              <button className="btn_type3" onClick={createTeam}>팀 생성</button>
            </div>
        </div>
        {/* TODO : 팀원 선택하는 부분 개발(현재 명확하게 설계가 되지 않은 프로세스 정의가 필요함.) */}
        <div className="">
            <span style={{"textAlign":"left"}}></span>
            
        </div>
        <div className="bt_area">
          <span className="num"></span>
          <button className="btn_type1 " onClick={updateEnterReqPartnerTeam}>선정완료</button>
        </div>
      </div>
    </div>
  );
}
export default TeamSelector;