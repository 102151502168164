import React from "react";
import moment from "moment";

import Layout from "../../layout/Layout.tsx";
import Slider from "../../layout/Slider.tsx";

import {fn_axios,fn_post_fileUpload_axios, getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';

import {useState,useEffect} from 'react';
import {Link,useLocation } from 'react-router-dom';

function UserReqCmptList() {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const [pageNum, setPageNum] = useState(queryParams.get('pageNum') ? parseInt(queryParams.get('pageNum')) : 1);
    const [pageMaxNum, setPageMaxNum] = useState(0);
    const [pageCnt, setPageCnt] = useState(10);
    const [paging, setPaging] = useState([]);
    const [cnt, setCnt] = useState(0); 
    const [searchMonth, setSearchMonth] = useState("");
    const [searchKeyword, setSearchKeyword] = useState(queryParams.get('searchKeyword') == "null" ? null : queryParams.get('searchKeyword'));
    const [ordColumn, setOrdColumn] = useState("REGR_DTM");
    const [user, setUser] = useState(null);

    const [selectedSigngu, setSelectedSigngu] = useState([]);
    const [signguList, setSignguList] = useState([]);
    
    
    const [cmptList, setCmptList] = useState([]);
    const [cmptStateDvisCd, setCmptStateDvisCd] = useState(queryParams.get('cmptStateDvisCd') == "null" ? null : queryParams.get('cmptStateDvisCd'));
    // const [gameKindCd, setGameKindCd] = useState("GAME_KIND_0020");
    // const [gameKindNm, setGameKindNm] = useState("배드민턴");


    




    const handleSearchKeyword = (e) => {
        setSearchKeyword(e.target.value);
    };

    const getUser = () => {
        const param = {
            method: 'GET',
            url:  `${API.BACKEND}/${API.VERSION}/user/getUser`,
            params: {userId : getCookieUserId()},
          };
          fn_axios(param, callbackGetUser, null);
      }
      const callbackGetUser = (res) => {  
        // setSignguDataList(resList);
        setUser(res.data.object);
        
      }
    
    const onClickPaging = (e) => {
        setPageNum(parseInt(e.target.text));
        //getPointPage(e.target.text);

        return null; 
    }
    const clickSearch = () => {
        getCmptPasing(pageNum);
    }


    const getLareaAddr = () => {
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/v1/lareaAddr/getLareaAddr`,
            params: null,
          };
          fn_axios(param, callBackGetLareaAddr, null);
    }
    const callBackGetLareaAddr = (res) => {  
        const resList = res.data.object;
        const tempList =resList.filter(item => (item.signguNm || '').trim() !== "");
    
        // setSignguDataList(resList);
        setSignguList(tempList);
      }

    const handleSignguChange = (e) => {
        setSelectedSigngu(e.target.value);
        return;
    };

    const handleCmptStatus = (e) => {
        setCmptStateDvisCd(e.target.value);
        return;
    };

    const getCmptPasing = (num) => {
        const param = {
			method : 'GET',
			url : `${API.BACKEND}/${API.VERSION}/cmpt/getCmptPaging`,
			params : {lareaAddrId: selectedSigngu
                    , pageNum : num
                    , cmptStateDvisCd : cmptStateDvisCd // CMPT_STATE_DIVS_CD_01(전체), CMPT_STATE_DIVS_CD_02(접수중), CMPT_STATE_DIVS_CD_04(접수마감), 04(대회종료)
                    , searchMonth : searchMonth
                    , searchKeyword : searchKeyword
                    , enterUserId : getCookieUserId()
                    , reqRsltCd : 'REQ_RSLT_CD_02' // 신청(결제)완료
                }
		}
		fn_axios(param,callbackGetCmptPasing,null);
    }

    

    const callbackGetCmptPasing = (res) => {
        setCmptList(res.data.object);
        setCnt(res.data.map.cntVo.cnt);
        var tempInt  = parseInt(res.data.map.cntVo.cnt%pageCnt);
        if(tempInt != 0 ) {
            tempInt = parseInt(res.data.map.cntVo.cnt/pageCnt) + 1;
        } else {
            tempInt = parseInt(res.data.map.cntVo.cnt/pageCnt);
        }
        settingPasing(tempInt);
    }

    const settingPasing = (maxNum) => {
        setPageMaxNum(parseInt(maxNum));
        // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
        var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2)  ;
        var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) +2;
        var tempList = [];

        if(maxNum != 0 ) {
            for (var i = start; i <= end; i++) {
                tempList.push(i);
            }
        }
        console.log(tempList);
        setPaging(tempList);
    }
    



    useEffect(() => {
        if(signguList.length === 0 ) {
            getLareaAddr();

            // if(queryParams.get('pageNum') != null && queryParams.get('pageNum') != 'null') {
            //     setPageNum(parseInt(queryParams.get('pageNum')));
            // }
            // if(queryParams.get('cmptStateDvisCd') != null && queryParams.get('cmptStateDvisCd') != 'null') {
            //     setCmptStateDvisCd(queryParams.get('cmptStateDvisCd'));
            // }
            // if(queryParams.get('searchMonth') != null && queryParams.get('searchMonth') != 'null') {
            //     setSearchMonth(queryParams.get('searchMonth'));
            // }
            // if(queryParams.get('searchKeyword') != null && queryParams.get('searchKeyword') != 'null') {
            //     setSearchKeyword(queryParams.get('searchKeyword'));
            // }
        }
      }, ); 

    useEffect(() => {
    getCmptPasing(pageNum);
    }, [pageNum]); 
    useEffect(() => {
        getUser();
    }, []); 

  return (
    <Layout>
         <div className="contents mypage apply">

            {/* <!-- mo ver id --> */}
            <div className="my_profile">
            <div className="my_id">
                <span>{user == null ? null : user.userNm}</span> <b>참가신청 대회</b> 
            </div>
            </div>
            {/* <!-- //mo ver id --> */}

            <section className="list_type1">
            {/* <!-- list top search --> */}
            <div className="bar">
                {/* <!-- pc ver id --> */}
                <div className="sub_title2 pc">My Page<span> / 참가신청한 대회</span></div>
                {/* <!-- //pc ver id --> */}
                <div className="tx_type03 odr3">신청대회 <b>{cnt}</b>건</div>
                <div className="search_box odr2">
                    <select name="area" value={selectedSigngu} onChange={handleSignguChange}>
                        <option value="">지역</option>
                        {signguList.map((item, index) => (
                        <option key={index} value={item.lareaAddrId} >
                            {item.spSignguNm} {item.signguNm}
                        </option>
                        ))}
                    </select>
                    <select name="state" value={cmptStateDvisCd} onChange={handleCmptStatus}>
                            <option value={""}>상태</option>
                            <option value={"CMPT_STATE_DIVS_CD_01"}>접수전</option>
                            <option value={"CMPT_STATE_DIVS_CD_02"}>접수중</option>
                            <option value={"CMPT_STATE_DIVS_CD_03"}>접수마감</option>
                            <option value={"CMPT_STATE_DIVS_CD_04"}>대회종료</option>
                    </select>
                    <div className="search">
                        <input type="text" name="keyword"  value={searchKeyword} onChange={handleSearchKeyword} placeholder="키워드"/>
                        <label htmlFor="keyword" onClick={clickSearch}>검색</label>
                    </div>
                </div>
                <div className="list_date odr4">
                    <a href="#" className={ordColumn == "REGR_DTM" ? "active":"" } onClick={() => setOrdColumn("REGR_DTM")}>등록일</a> &#47;
                    <a href="#" className={ordColumn == "CMPT_END_DT" ? "active":"" } onClick={() => setOrdColumn("CMPT_END_DT")}>마감일</a> &#47;
                    <a href="#" className={ordColumn == "CMPT_L_NM" ? "active":"" } onClick={() => setOrdColumn("CMPT_L_NM")}>이름순</a>
                </div>
            </div>
            {/* <!-- //list top search --> */}
            {/* <!-- list --> */}
            <ul className="list">
                        {cmptList.map((item, index) => (
                                <li key={index} onClick={() => window.location.href = `/cmpt/cmptDetail?sourceUrl=reqCmpt&cmptLId=${item.cmptLId}&pageNum=${pageNum}&cmptStateDvisCd=${cmptStateDvisCd}&searchKeyword=${searchKeyword}&prevCmptLId=${index > 0 ? cmptList[index - 1].cmptLId : null}&prepCmptLId=${index < cmptList.length - 1 ? cmptList[index + 1].cmptLId : null}`} 
                                        className={(item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" || item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_04")  ? "end" : null}>
                                {/* <Link to={`/cmpt/cmptDetail?cmptLId=${item.cmptLId}`} className="none" >  */}
                                <b >{item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(item.cmptReqDday) >= 0 ? "D-" + Math.abs(item.cmptReqDday) : "D+" + Math.abs(item.cmptReqDday) ) : 
                                                                            (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(item.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
                                                                            (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감"))} 
                                                                            &nbsp;&nbsp;{item.cmptLNm} </b> 
                                {moment(item.cmptStrDt).format('YYYY-MM-DD')} ~ {moment(item.cmptEndDt).format('YYYY-MM-DD')} / {item.gymNm == null ? "(체육관 정보 없음)" : item.gymNm} 
                                <span>주최 : ({item.cmptLHostNm})</span>
                                {/* </Link> */}
                                </li>
                        ))}
                    </ul>
                <div className="paging">
                    {paging.map((item, index) => (
                        <a href="#" key={item} onClick={onClickPaging} className={pageNum == item ? "on" :""}>{item}</a>
                    ))}
                </div>
            {/* <!-- //list --> */}
            </section>
        </div>
    </Layout>
    );
  }
  
  export default UserReqCmptList;