import React, { useEffect, useState } from "react";

function Sidebar() {
    const [toggleStates, setToggleStates] = useState({
        userToggle: false,
        groundToggle: false,
        systemToggle: false,
    });

    const toggleSection = (section) => {
        setToggleStates((prevStates) => ({
            ...prevStates,
            [section]: !prevStates[section],
        }));
    };

    return (
        <aside className="admin_sidebar">
            <div className="admin_logo">
                <a onClick={() => window.location.href = "/"}>
                    <img src="/assets/images/common/logo.png" alt="woog logo" />
                </a>
            </div>

            <button className="user_toggle_button" onClick={() => toggleSection("userToggle")}>
                {toggleStates.userToggle ? "사용자관련 ▼" : "사용자관련 ▲"}
            </button>
            <nav className={`user_menu ${toggleStates.userToggle ? "open" : "closed"}`}>
                <ul>
                    <li><a href="/admin/userMng">사용자 관리</a></li>
                    <li><a href="#">대회 마스터 관리</a></li>
                    <li><a href="#">단체 관리</a></li>
                </ul>
            </nav>

            <button className="ground_toggle_button" onClick={() => toggleSection("groundToggle")}>
                {toggleStates.groundToggle ? "그라운드관련 ▼" : "그라운드관련 ▲"}
            </button>
            <nav className={`ground_menu ${toggleStates.groundToggle ? "open" : "closed"}`}>
                <ul>
                    <li><a href="#">권한 관리</a></li>
                    <li><a href="#">체육관 관리</a></li>
                </ul>
            </nav>

            <button className="system_toggle_button" onClick={() => toggleSection("systemToggle")}>
                {toggleStates.systemToggle ? "시스템관련 ▼" : "시스템관련 ▲"}
            </button>
            <nav className={`system_menu ${toggleStates.systemToggle ? "open" : "closed"}`}>
                <ul>
                    <li><a href="#">권한 관리</a></li>
                    <li><a href="/admin/codeMng">코드 관리</a></li>
                    <li><a href="#">공지사항 관리</a></li>
                    <li><a href="#">배너 관리</a></li>
                    <li><a href="#">문의 관리</a></li>
                </ul>
            </nav>
        </aside>
    );
}

export default Sidebar;