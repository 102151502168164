import React from "react";
import {useState} from 'react';

interface CmptDetailItemProps {
  propCmptEnterMmntList: any;
}

const CmptDetailItem : React.FC<CmptDetailItemProps> = ({ propCmptEnterMmntList })=> {
  const [cmptEnterMmntList, setCmptEnterMmntList] = useState(propCmptEnterMmntList); 
  
  console.log(propCmptEnterMmntList);
return (
      <div className="info" >
        {cmptEnterMmntList == null || cmptEnterMmntList.length == 0 ? 
        <div>
          <h3>등록된 경품/기념품 정보가 없습니다.</h3>
        </div>
        : cmptEnterMmntList.map((item, index) => (
                <div>
                  {item.cmptMmntFileId == null ? null : <img src={item.cmptMmntFileUrl} alt={item.cmptMmntFileNm}/>}
                  <p className='tit'>{item.cmptMmntNm + (item.cmptMmntNum == 0 ? "" : (  " : " + item.cmptMmntNum + "개")).toString()}</p>
                  <p className='tx_info' dangerouslySetInnerHTML={{__html:item.cmptMmntCntn}}></p>
                </div>
                ))}
      </div>
  );
}

export default CmptDetailItem;
