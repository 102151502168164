import React from "react";
import {useState,useEffect} from 'react';

import {fn_axios,fn_post_fileUpload_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';


interface CmptDetailGameProp {
    propCmpt: any;
  }

const CmptDetailGame : React.FC<CmptDetailGameProp> = ({propCmpt}) => {
    const [cmpt, setCmpt] = useState(propCmpt);
    const [cmptGameList, setCmptGameList] = useState([]);
    const [cmptGameDivsMList, setCmptGameDivsMList] = useState([]);
    const [cmptGameDivsList1, setCmptGameDivsList1] = useState([]);
    const [cmptGameDivsList2, setCmptGameDivsList2] = useState([]);
    const [cmptGameDivsList3, setCmptGameDivsList3] = useState([]);

    const [cmptGameDivs1, setCmptGameDivs1] = useState(null);
    const [cmptGameDivs2, setCmptGameDivs2] = useState(null);
    const [cmptGameDivs3, setCmptGameDivs3] = useState(null);

    const [pageNum, setPageNum] = useState(1);
    const [pageMaxNum, setPageMaxNum] = useState(0);
    const [pageCnt, setPageCnt] = useState(10);
    const [paging, setPaging] = useState([]);
    // const [searchKeyword, setSearchKeyword] = useState("");
  

    const handleCmptGameDivs1 = (e) => {
        setCmptGameDivs1(e.target.value);
    }
    const handleCmptGameDivs2 = (e) => {
        setCmptGameDivs2(e.target.value);
    }
    const handleCmptGameDivs3 = (e) => {
        setCmptGameDivs3(e.target.value);
    }


    const getCmptGameDivs = () => {
        const param = {
          method : 'GET',
          url : `${API.BACKEND}/${API.VERSION}/cmptGame/getCmptGameDivs`,
          params : {    cmptLId: cmpt.cmptLId
                      , mdtrGameOptnYn : "Y"
                        // , cmptGameId : cmptGame.cmptGameId
                    }
          }
        fn_axios(param,callbackGetCmptGameDivs,null);
    }
      
    const callbackGetCmptGameDivs = (res) => {
        console.log(res);
        setCmptGameDivsList1(res.data.map.cmptGameDivsL1);
        setCmptGameDivsList2(res.data.map.cmptGameDivsL2);
        setCmptGameDivsList3(res.data.map.cmptGameDivsL3);
        setCmptGameDivsMList(res.data.map.cmptGameDivsM);
    }

    const getCmptGamePaging = (num) => {
        setCmptGameList(null);
        var tempList = [];
        if(cmptGameDivs1 != null && cmptGameDivs1 != "") {
            tempList.push(cmptGameDivs1);
        }
        if(cmptGameDivs2 != null && cmptGameDivs2 != "") {
            tempList.push(cmptGameDivs2);
        }
        if(cmptGameDivs3 != null && cmptGameDivs3 != "") {
            tempList.push(cmptGameDivs3);
        }
        console.log(tempList);

        const param = {
        method : 'GET',
        url : `${API.BACKEND}/${API.VERSION}/cmptGame/getCmptGamePaging`,
        params : {    cmptLId: cmpt.cmptLId
                      , pageNum : num
                      , mdtrGameOptnYn : "Y"
                    //   , searchKeyword : searchKeyword
                      , gameDivsLList : tempList.join(",")
                  }
      }
      fn_axios(param,callbackGetCmptGamePaging,null);
    
    }
    const callbackGetCmptGamePaging = (res) => {
        setCmptGameList(res.data.object);
          var tempInt  = parseInt(res.data.map.cnt%pageCnt);
          if(tempInt != 0 ) {
              tempInt = parseInt(res.data.map.cnt/pageCnt) + 1;
          } else {
              tempInt = parseInt(res.data.map.cnt/pageCnt);
          }
          settingPasing(tempInt);
    }

    const settingPasing = (maxNum) => {
        setPageMaxNum(parseInt(maxNum));
        // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
        var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2)  ;
        var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) +2;
        var tempList = [];
    
        if(maxNum != 0 ) {
            for (var i = start; i <= end; i++) {
                tempList.push(i);
            }
        }
        setPaging(tempList);
    }
    const onClickPaging = (e) => {
        setPageNum(parseInt(e.target.text));
      
        return null; 
      }
    useEffect(() => {
        console.log("TEST");
        getCmptGamePaging(pageNum);
    }, [pageNum]); 

    useEffect(() => {
        if(pageNum == 1) {
            getCmptGamePaging(pageNum); 
        } else {
            setPageNum(1);
        }
    }, [cmptGameDivs1,cmptGameDivs2,cmptGameDivs3]); 

    useEffect(() => {
        if(cmptGameDivsMList == null || cmptGameDivsMList.length == 0) {
            getCmptGameDivs();
        }
        
    }, []); 
      
    
    return (
        <div className="info">
            {/* <!-- select --> */}
            <div className="search_box">
            {(cmptGameDivsList1 == null || cmptGameDivsList1.length == 0)  ? null : 
            <select value={cmptGameDivs1 == null ? "" : cmptGameDivs1} onChange={handleCmptGameDivs1}>
            <option value={""}>{cmptGameDivsList1[0].gameDivsNm}</option>
            {cmptGameDivsList1.map((item, index) => (
                <option key={item.gameDivsLId} value={item.gameDivsLId}>{item.gameDivsLNm}</option>
            ))}
            </select>
            }
            {/* 배드민턴의 경우 연령 임시로 안보이게 설정  */}
            { (cmptGameDivsList2 == null || cmptGameDivsList2.length == 0) 
              || (cmpt != null && cmpt.gameKindCd == "GAME_KIND_0020" && cmptGameDivsList2[0].gameClsfKindCd == 'GAME_CLSF_KIND_CD_01')
            ? null : 
            <select name={cmptGameDivs2 == null ? "" : cmptGameDivs2} onChange={handleCmptGameDivs2}>
            <option value={""}>{cmptGameDivsList2[0].gameDivsNm}</option>
            {cmptGameDivsList2.map((item, index) => (
                <option key={item.gameDivsLId} value={item.gameDivsLId}>{item.gameDivsLNm}</option>
            ))}
            </select>
            }
            {(cmptGameDivsList3 == null || cmptGameDivsList3.length == 0)  ? null : 
            <select name={cmptGameDivs3 == null ? "" : cmptGameDivs3} onChange={handleCmptGameDivs3}>
            <option value={""}>{cmptGameDivsList3[0].gameDivsNm}</option>
            {cmptGameDivsList3.map((item, index) => (
                <option key={item.gameDivsLId} value={item.gameDivsLId}>{item.gameDivsLNm}</option>
            ))}
            </select>
            }

            </div>
            {/* <!-- //select --> */}
            {/* <!-- table --> */}
            <div className="table_type1 default">
                <table>
                <colgroup>
                    <col style={{width:"11%"}} />
                    <col style={{width:"23%"}} />
                    {(cmptGameDivsList1 == null || cmptGameDivsList1.length == 0) ? null : 
                     <col style={{width:"auto"}} />
                    }
                    {(cmptGameDivsList2 == null || cmptGameDivsList2.length == 0)  ? null : 
                     <col style={{width:"auto"}} />
                    }
                    {(cmptGameDivsList3 == null || cmptGameDivsList3.length == 0)   ? null : 
                     <col style={{width:"auto"}} />
                    }
                    {/* <col style={{width:"auto"}} /> */}
                </colgroup>
                <thead>
                    <th>No.</th>
                    <th>세부종목명</th>
                    {(cmptGameDivsList1 == null || cmptGameDivsList1.length == 0) ? null : 
                    <th >{cmptGameDivsList1[0].gameDivsNm}</th>
                    }
                    {(cmptGameDivsList2 == null || cmptGameDivsList2.length == 0)  ? null : 
                    <th>{cmptGameDivsList2[0].gameDivsNm}</th>
                    }
                    {(cmptGameDivsList3 == null || cmptGameDivsList3.length == 0)   ? null : 
                    <th>{cmptGameDivsList3[0].gameDivsNm}</th>
                    }
                    {/* <th>신청인원</th>  임시삭제*/}
                </thead>
                <tbody>
                    {(cmptGameList == null || cmptGameList.length == 0 ) ? 
                    <tr>
                        <td></td>
                        <td colSpan={cmptGameDivsList1 == null || cmptGameDivsList1.length == 0 ? 2 : 
                                    cmptGameDivsList2 == null || cmptGameDivsList2.length == 0 ? 3 : 
                                    cmptGameDivsList3 == null || cmptGameDivsList3.length == 0 ? 4 : 5}
                                    >검색 조건에 해당하는 세부종목이 없습니다. </td>
                    </tr>
                    : cmptGameList.map((item, index) => (
                        <tr key={item.no}>
                        <td>{item.no}</td>
                        <td>{item.gameLNm}</td>
                        {item.gameDivsLValue0 == null ? null : <td>{item.gameDivsLValue0}</td>}
                        {item.gameDivsLValue1 == null ? null : <td>{item.gameDivsLValue1}</td>}
                        {item.gameDivsLValue2 == null ? null : <td>{item.gameDivsLValue2}</td>}
                        {/* <td>{item.enterPersonNum}/{item.allPersonNum}</td> */}
                        </tr>
                    ))}
                </tbody>
                </table>
            </div>
            {/* <!-- //table --> */}
            {/* <!-- paging --> */}
            {cmptGameList == null ? null : 
            <div className="paging">
                {paging.map((item, index) => (
                    <a href="#" key={item} onClick={onClickPaging} className={pageNum == item ? "on" :""}>{item}</a>
                ))}
            </div>
            }
            {/* <!-- //paging --> */}
        </div>

    );
  }
  
export default CmptDetailGame;