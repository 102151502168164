import React from "react";
import {useState,useEffect} from 'react';
import moment from "moment";
import { useLocation } from 'react-router-dom';

import {fn_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";
import Slider from "../../layout/Slider.tsx";
import  {initializeKeenSlider7} from "../../assets/js/ui.js";
import KakaoMap from "../../config/KakaoMap.tsx";

function GymDetail() {

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const pageNum = queryParams.get('pageNum');
  const lareaAddrId = queryParams.get('lareaAddrId');
  const spSignguCd  = queryParams.get('spSignguCd');
  const searchKeyword = queryParams.get('searchKeyword');
  const gymTypeCd = queryParams.get('gymTypeCd');
  const gymLttd = queryParams.get('gymLttd');
  const gymLgtd = queryParams.get('gymLgtd');

  const gymId = queryParams.get('gymId');

  const [gym, setGym] = useState(null);
  const [gymPictList, setGymPictList] = useState([]);

  const apiKey = process.env.REACT_APP_KAKAO_MAPS_KEY;
  //const latitube = ''; // 위도
  //const longitube = ''; // 경도

  const getGym = () => {
    const param = {
        method: 'GET',
        url: `${API.BACKEND}/${API.VERSION}/gym/getGym`,
        params: {gymId : gymId}
      };
      fn_axios(param, callbackGetGym, null);
  }
  
  const callbackGetGym = (res) => {  
    console.log(res.data.object);
    setGym(res.data.object);
    setGymPictList(res.data.map.gymPictList);
  }


  useEffect(() => {
    if(gym == null  ) {
      getGym();
    }
  }, []); 

  useEffect(() => {
    if(gym != null  ) {
      initializeKeenSlider7();
    }
  }, [gym]); 


return (
    <Layout>
      <div className="contents ground">

        <h1 className="sub_title" style={{cursor:"pointer"}} onClick={() => window.location.href = `/gym/gymList?pageNum=${pageNum}&lareaAddrId=${lareaAddrId}&spSignguCd=${spSignguCd}&searchKeyword=${searchKeyword}&gymTypeCd=${gymTypeCd}`}>그라운드 / {gym == null ? "" : gym.lareaAddrNm}</h1>

        <section className="ground_section">
          {/* <!-- mo ver title --> */}
          <h2 className="mo" style={{cursor:"pointer"}} onClick={() => window.location.href = `/gym/gymList?pageNum=${pageNum}&lareaAddrId=${lareaAddrId}&spSignguCd=${spSignguCd}&searchKeyword=${searchKeyword}&gymTypeCd=${gymTypeCd}`}><span>체육관명</span>{gym == null ? "" : gym.gymNm}</h2>
          {/* <!-- //mo ver title --> */}
          
          {/* <!-- ground slide --> */}
          <div className="slide">
            <div id="my-keen-slider7" className="keen-slider slide_box"> 
            {(gymPictList == null || gymPictList.length ==0 ) ? 
              <div className="silde01 keen-slider__slide number-slide1">
                <img src="/assets/images/02_ground/ground_slide_img01.jpg" alt=""/>
              </div>
            :
              gymPictList.map((item, index) => (
                <div className={`silde01 keen-slider__slide number-slide${index+1}`}>
                  <img src={item.atchFileUrl} alt={item.realAtchFileNm}/>
                </div>
              ))}
            </div>
          </div>
          {/* <!-- //ground slide --> */}

          {/* <!-- content --> */}
          <div className="ground_content">
            {/* <!-- pc ver title --> */}
            <h2 className="pc">{gym == null ? "" : gym.gymNm}</h2>
            {/* <!-- //pc ver title --> */}
            <ul>
              <li>
                <p>주소</p>
                <span>: {gym == null ? "" : gym.dtlAddr}</span>
              </li>
              <li>
                <p>체육관 유형</p>
                <span>: {gym == null ? "" : gym.gymTypeNm}</span>
              </li>
              <li>
                <p>연락처</p>
                <span>: {gym == null ? "" : gym.gymCadr == "" ? "정보없음" : gym.gymCadr}</span>
              </li>
              <li>
                <p>담당부서</p>
                <span>: {gym == null ? "" : gym.gymRspsb == "" ? "정보없음" : gym.gymRspsb}</span>
              </li>
              <li>
                <p>홈페이지</p>
                <span>: {gym == null ? "" : gym.gymHmpg == "" ? "정보없음" : <a href={gym.gymHmpg} target="_blank" style={{cursor:"POINTER",textDecoration:"underline"}}>{gym.gymHmpg}</a>} </span>
              </li>
              {/* <li>
                <p>가능종목</p>
                <span>농부, 배구, 탁구, 배드민턴, 핸드볼 등</span>
              </li> */}
              {/* <li>
                <p>규모</p>
                <span>지하1층, 지상2층 / 면적 : 5,743㎡</span>
              </li> */}
              <li>
                <p>체육관 유형</p>
                <span>: {gym == null ? "" : gym.gymTypeNm}</span>
              </li>
              <li>
                <p>수용인원</p>
                <span>: {gym == null ? "" : gym.accmdPerson} 석</span>
              </li>
              <li>
                <p>코트수</p>
                <span>: {gym == null ? "" : gym.cortNum} 석</span>
              </li>
              <li>
                <p>관람석</p>
                <span>: {gym == null ? "" : gym.seatNum} 석</span>
              </li>
              <li>
                <p>이용금액</p>
                {/* <span>: 평일 5,000,000원 / 휴일 10,000,000 원</span> */}
                <span>: 정보없음</span>
              </li>
              <li>
                <p>주차시설</p>
                {/* <span>지상주차 500대 / 지하주차 1,000대</span> */}
                <span>: 정보없음</span>
              </li>
              <li>
                <p>주차비용</p>
                {/* <span>기본 2,000원 / 10분 1,000원</span> */}
                <span>: 정보없음</span>
              </li>
              <li>
                <p>부대시설</p>
                {/* <span>화장실, 샤워장, 라커룸, 편의점, 기념품샵</span> */}
                <span>: 정보없음</span>
              </li>
            </ul>
            {/* <!-- map --> */}
            <div className="map">
              {/*
              <img src="/assets/images/02_ground/map.jpg" alt="map" className="pc"/>
              <img src="/assets/images/02_ground/mo/map.jpg" alt="map" className="mo"/>
              <KakaoMap apiKey={apiKey} latitude={gym.gymLttd} longitube={gym.gymLgtd} ></KakaoMap>
            */}
            <KakaoMap apiKey={apiKey} latitude={gym == null ? "" : gym.gymLttd} longitube={gym == null ? "" : gym.gymLgtd} ></KakaoMap>
            </div>
            {/* <!-- //map --> */}
          </div>
          {/* <!-- //content --> */}
        </section>
      <Slider></Slider>
      </div>
    </Layout>
  );
}

export default GymDetail;
