import React from "react";
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';


import {fn_axios_post_excel_down,fn_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";

function APITest() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cmptLId = queryParams.get('cmptLId');
  const gameKindNm = queryParams.get('gameKindNm');
  const [cmptMList, setCmptMList] = useState(null);

  const [pageNum, setPageNum] = useState(1);   
  const [pageMaxNum, setPageMaxNum] = useState(0);
  const [pageCnt, setPageCnt] = useState(10);
  const [paging, setPaging] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  


  const getCmptMPaging = (num) => {
      setCmptMList(null);

      const param = {
      method : 'GET',
      url : `${API.BACKEND}/${API.VERSION}/cmpt/getCmptMPaging`,
      params : {      pageNum : num
                    , searchKeyword : searchKeyword
                    , userId : getCookieUserId()
                }
    }
    fn_axios(param,callbackGetCmptMPaging,null);
  
  }
  const callbackGetCmptMPaging = (res) => {
    console.log(res);
    setCmptMList(res.data.object);
        var tempInt  = parseInt(res.data.map.cnt%pageCnt);
        if(tempInt != 0 ) {
            tempInt = parseInt(res.data.map.cnt/pageCnt) + 1;
        } else {
            tempInt = parseInt(res.data.map.cnt/pageCnt);
        }
        settingPasing(tempInt);
  }

  const settingPasing = (maxNum) => {
      setPageMaxNum(parseInt(maxNum));
      // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
      var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2)  ;
      var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) +2;
      var tempList = [];
  
      if(maxNum != 0 ) {
          for (var i = start; i <= end; i++) {
              tempList.push(i);
          }
      }
      setPaging(tempList);
  }
  const getCmptManageExcelDown = (lId) => {
    // setBoard(null);
    if (lId == null || lId == "") {
      lId = cmptLId
    }
    const param = {
      url : `${API.BACKEND}/${API.VERSION}/cmpt/getCmptMExcelDown`,
      params : {      searchKeyword : searchKeyword
                    , cmptLId : lId
                }
      };
      fn_axios_post_excel_down(param, null,null);
  }
  const onClickExcelDown = () => {
    getCmptManageExcelDown(cmptLId);
  }

  const deleteCmptM = (cmptMId) => {
    console.log('call');
      const param = {
      method : 'DELETE',
      url : `${API.BACKEND}/${API.VERSION}/cmpt/deleteCmptM`,
      params : {      cmptMId : cmptMId
                }
    }
    fn_axios(param,callbackDeleteCmptM,null);
  
  }
  const callbackDeleteCmptM = (res) => {
    console.log(res);
    alert(res.data.message);
  }
  const createCmptM = () => {
      const param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/cmpt/createCmptM`,
      params : {        gameKindCd: 'GAME_KIND_0099'
                      , cmptMNm: '2024 어울림의 힘(장애인과 비장애인의 어울림'
                      , enterLareaCd: 'ENTER_LAREA_CD_03'
                      , enterTrgtCd: 'ENTER_TRGT_CD_02'
                      , cmptLareaAddrId:142
                      , cmptHmpgUrl:'https://wooriground.com'
                      , userId: 21
                }
    }
    fn_axios(param,callbackCreateCmptM,null);
  
  }
  const callbackCreateCmptM = (res) => {
    console.log(res);
    alert(res.data.message);
  }
  const updateCmptM = () => {
    const param = {
    method : 'POST',
    url : `${API.BACKEND}/${API.VERSION}/cmpt/updateCmptM`,
    params : {        cmptMId: 'GAME_KIND_0099_000002'
                    , gameKindCd: 'GAME_KIND_0099'
                    , cmptMNm: '12121212'
                    , enterLareaCd: 'ENTER_LAREA_CD_03'
                    , enterTrgtCd: 'ENTER_TRGT_CD_02'
                    , cmptLareaAddrId:142
                    , cmptHmpgUrl:'https://wooriground.com'
                    , updateUserId: 21
              }
  }
  fn_axios(param,callbackUpdateCmptM,null);

}
const callbackUpdateCmptM = (res) => {
  console.log(res);
  alert(res.data.message);
}
  // 대회 마스터 조회
  const getCmptM = () => {
    const param = {
      method: 'GET',
      url: `${API.BACKEND}/v1/cmpt/getCmptM`,
      params: {        cmptMId: 'GAME_KIND_0099_000001'},
    };
    fn_axios(param, callbackCmptM, null);
  };
  const callbackCmptM = (res) => {
    const data = res.data;
    console.log(data);
    if (data.status) {
    }
  }
  // 대회 내역 조회
  const getCmptL = () => {
    const param = {
      method: 'GET',
      url: `${API.BACKEND}/v1/cmpt/getCmpt`,
      params: {        cmptLId: '240215_GAME_KIND_0020_000002_0117'},
    };
    fn_axios(param, callbackGetCmptL, null);
  };
  const callbackGetCmptL = (res) => {
    const data = res.data;
    console.log(data);
    if (data.status) {
    }
  }
  // 대회 별 문의내역 조회
  const getQustAnswPaging = (num) => {
    const param = {
      method: 'GET',
      url: `${API.BACKEND}/${API.VERSION}/qust/getQustAnswPaging`,
      params: { cmptLId: '240215_GAME_KIND_0020_000002_0117'
              , pageNum : num
              , searchKeyword : searchKeyword
              , userId : getCookieUserId()
            }
    };
    fn_axios(param, callbackGetQustAnswPaging, null);
  };
  const callbackGetQustAnswPaging = (res) => {
    const data = res.data;
    console.log(data);
    if (data.status) {
    }
  }

  // 대회 별 문의 답변하기
  const updateQustAnsw = () => {
    const param = {
      method: 'POST',
      url: `${API.BACKEND}/${API.VERSION}/qust/updateQustAnsw`,
      params: { qustId : 5
              , answCntn : "TEST"
              , updateUserId : getCookieUserId()
            }
    };
    fn_axios(param, callbackUpdateQustAnsw, null);
  };
  const callbackUpdateQustAnsw = (res) => {
    const data = res.data;
    console.log(data);
    if (data.status) {
    }
  }
  // 경기 종목 조회.
  const getGameItem = () => {
    const param = {
      method: 'GET',
      url: `${API.BACKEND}/v1/gameItem/getGameItem`,
      params: null,
    };
    fn_axios(param, callBackGetGameItem, null);
  };
  const callBackGetGameItem = (res) => {
    const data = res.data;
    console.log(data);
    if (data.status) {
    }
  }
    // 단체 페이징 처리.
    const getGroupPaging = (num) => {
      const param = {
        method: 'GET',
        url: `${API.BACKEND}/${API.VERSION}/group/getGroupPaging`,
        params: {  pageNum : num
                , searchKeyword : "우리"
                // , userId : getCookieUserId()
              }
      };
      fn_axios(param, callbackGetGroupPaging, null);
    };
    const callbackGetGroupPaging = (res) => {
      const data = res.data;
      console.log(data);
      if (data.status) {
      }
    }
    // 단체 조회 처리.
    const getGroup = () => {
      const param = {
        method: 'GET',
        url: `${API.BACKEND}/${API.VERSION}/group/getGroup`,
        params: {  groupId : 2
              }
      };
      fn_axios(param, callbackGetGroup, null);
    };
    const callbackGetGroup = (res) => {
      const data = res.data;
      console.log(data);
      if (data.status) {
      }
    }

  useEffect(() => {
    // getCmptMPaging(1);
    }, []); 

  return (
  <Layout>
  <div className="contents contest">
    <h3>API 테스트 용</h3>
    <div>
    <button onClick={onClickExcelDown}>Excel Download</button>
    </div>
    <div> 
    <button onClick={() => deleteCmptM('GAME_KIND_0099_000002')}>cmpt Delete Test</button>
    </div>
    <div> 
    <button onClick={() => createCmptM()}>cmpt Create Test</button>
    </div>
    <div> 
    <button onClick={() => updateCmptM()}>cmpt Update Test</button>
    </div>
    <div> 
    <button onClick={() => getCmptM()}>대회 마스터 조회</button>
    </div>
    <div> 
    <button onClick={() => getGameItem()}>경기 종목 조회</button>
    </div>
    <div> 
    <button onClick={() => getCmptL()}>대회 내역 조회</button>
    </div>
    <div> 
    <button onClick={() => getQustAnswPaging(1)}>문의 내역 조회</button>
    </div>
    <div> 
    <button onClick={() => updateQustAnsw()}>문의 답변 달기</button>
    </div>
    <div> 
    <button onClick={() => getGroupPaging(1)}>단체 목록 조회하기</button>
    </div>
    <div> 
    <button onClick={() => getGroup()}>단체 조회하기</button>
    </div>
  </div>
  </Layout>
  );
}
export default APITest;