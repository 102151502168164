import React from "react";
import {useState} from 'react';
import moment from "moment";
import {getWeek,CONSTANTS} from '../../common/common.tsx';

interface CmptDetailMainProps {
  propCmpt: any;
  propCmptAtchFileList: any;
  propCmptGym: any;
}

const CmptDetailMain : React.FC<CmptDetailMainProps> = ({ propCmpt, propCmptAtchFileList,propCmptGym })=> {
  const [cmpt, setCmpt] = useState(propCmpt);
  const [cmptAtchFileList, setCmptAtchFileList] = useState(propCmptAtchFileList);
  const [cmptGym, setCmptGym] = useState(propCmptGym);



  const handleDownload = async (index) => {
    try {
      const res = await fetch(cmptAtchFileList[index].atchFileUrl);
      const blob = await res.blob();
      const downloadUrl = window.URL.createObjectURL(blob); // 이 과정이 필요하다.

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = cmptAtchFileList[index].atchFileNm;
      link.click()
    } catch (error) {
      console.error('Error downloading file:', error);
    }
    return;
  };

  return (
    <div>
      {/* <!-- content info --> */}
      <div className="info accordion"  >
        {/* <!-- file --> */} 
        <div className="file">
        {cmptAtchFileList == null ? null : cmptAtchFileList.map((item, index) => (
          <a style={{cursor:"pointer", textDecoration: "underline"}} onClick={() => handleDownload(index)}><p id={index}><b>첨부파일 {index + 1}. {item.atchFileNm}</b></p></a>
          ))}
        </div>
        {/* TODO : 공연 임시 */}
        {cmpt != null && cmpt.gameKindCd == CONSTANTS.GAME_KIND_0075 ? 
          <>
            <h3><b>공연 개요</b></h3>

            <div><b>1. 공연명 : </b><span>{cmpt == null ? null : cmpt.cmptLNm }</span></div>
            {/* <div><b>2. 일시 : </b><span>{cmpt == null ? null : moment(cmpt.cmptStrDt).format('YYYY-MM-DD') + "(" + getWeek(cmpt.cmptStrDt) +  ") ~ " + moment(cmpt.cmptEndDt).format('YYYY-MM-DD') + "("  + getWeek(cmpt.cmptEndDt) + ")"} </span></div> */}
            <div><b>2. 일시 : </b><span>{cmpt == null ? null : moment(cmpt.cmptStrDt).format('YYYY-MM-DD') + " ~ " + moment(cmpt.cmptEndDt).format('YYYY-MM-DD') }</span></div>
            <div><b>3. 예매신청 기간 : </b><span>{cmpt == null ? null : moment(cmpt.cmptReqStrDtm).format('YYYY-MM-DD HH:mm') + " ~ " + moment(cmpt.cmptReqEndDtm).format('YYYY-MM-DD HH:mm') } </span></div>
            <div><b>4. 장소 : </b>
            {cmptGym == null ?  null : cmptGym.map((item, index) => (
            <span  style={{cursor:"POINTER",textDecoration:"underline"}}onClick={() => window.location.href = `/gym/gymDetail?gymId=${item.gymId}`}>{item.gymNm }</span>
            ))}
            </div>
            <div><b>5. 주최 : </b><span>{cmpt == null ? null : cmpt.cmptLHostNm }</span></div>
            <div><b>6. 주관 : </b><span>{cmpt == null ? null : cmpt.cmptLSpitdNm }</span></div>
            <div><b>7. 후원 : </b><span>{cmpt == null ? null : cmpt.cmptLSponNm }</span></div>
            {/* <div><b>8. 협찬 : </b><span>{cmpt == null ? null : cmpt.cmptLSpsrNm }</span></div> */}
            {/* <div><b>9. 경기종목수(세부종목) : </b><span>{cmpt == null ? null : cmpt.gameKindNum } 종목</span></div> */}
          </>
        :
        <>
          <h3><b>대회 개요</b></h3>

          <div><b>1. 대회명 : </b><span>{cmpt == null ? null : cmpt.cmptLNm }</span></div>
          {/* <div><b>2. 일시 : </b><span>{cmpt == null ? null : moment(cmpt.cmptStrDt).format('YYYY-MM-DD') + "(" + getWeek(cmpt.cmptStrDt) +  ") ~ " + moment(cmpt.cmptEndDt).format('YYYY-MM-DD') + "("  + getWeek(cmpt.cmptEndDt) + ")"} </span></div> */}
          <div><b>2. 일시 : </b><span>{cmpt == null ? null : moment(cmpt.cmptStrDt).format('YYYY-MM-DD') + " ~ " + moment(cmpt.cmptEndDt).format('YYYY-MM-DD') }</span></div>
          <div><b>3. 참가신청 기간 : </b><span>{cmpt == null ? null : moment(cmpt.cmptReqStrDtm).format('YYYY-MM-DD HH:mm') + " ~ " + moment(cmpt.cmptReqEndDtm).format('YYYY-MM-DD HH:mm') } </span></div>
          <div><b>4. 장소 : </b>
          {cmptGym == null ?  null : cmptGym.map((item, index) => (
          <span  style={{cursor:"POINTER",textDecoration:"underline"}}onClick={() => window.location.href = `/gym/gymDetail?gymId=${item.gymId}`}>{item.gymNm }</span>
          ))}
          </div>
          <div><b>5. 주최 : </b><span>{cmpt == null ? null : cmpt.cmptLHostNm }</span></div>
          <div><b>6. 주관 : </b><span>{cmpt == null ? null : cmpt.cmptLSpitdNm }</span></div>
          <div><b>7. 후원 : </b><span>{cmpt == null ? null : cmpt.cmptLSponNm }</span></div>
          <div><b>8. 협찬 : </b><span>{cmpt == null ? null : cmpt.cmptLSpsrNm }</span></div>
          <div><b>9. 경기종목수(세부종목) : </b><span>{cmpt == null ? null : cmpt.gameKindNum } 종목</span></div>
          </>
        }
        <div dangerouslySetInnerHTML={{ __html: cmpt == null ? null : cmpt.cmptLCntn }}></div>
      </div>
      {/* <!-- //content info --> */}

      {/* <!-- info accordion --> */}{/* TODO : 공연 임시 */}
      <div className="btn_accordion mo">{cmpt != null && cmpt.gameKindCd == CONSTANTS.GAME_KIND_0075 ? "공연 정보 더보기" : "대회요강 세부항목 보기"}</div>

      
    </div>

  );
}
export default CmptDetailMain;