import React from "react";
import {useState,useEffect} from 'react';
import moment from "moment";
import { useLocation } from 'react-router-dom';


import {fn_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";
import Slider from "../../layout/Slider.tsx";

function EventList() {

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const eventLId = queryParams.get('eventLId');

  const [boardList, setBoardList] = useState([]);
  const [board, setBoard] = useState(null);
  const [cnt, setCnt] = useState(null);
  const [eventDivsCd, setEventDivsCd] = useState("");
  const [boardAtchFileList, setBoardAtchFileList] = useState([]);
  const [boardComtList, setBoardComtList] = useState([]);
  const [boardCntnList, setBoardCntnList] = useState([]);
  const [comtCntn, setComtCntn] = useState("");

  const [pageNum, setPageNum] = useState(1);
  const [pageMaxNum, setPageMaxNum] = useState(0);
  const [pageCnt, setPageCnt] = useState(10);
  const [paging, setPaging] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");


  const handleBoardComt = (e) => {
    setComtCntn(e.target.value);
  }
  const handleEventDivsCd = (e) => {
    setEventDivsCd(e.target.value);
  }


  const getBoardPaging = (num) => {
    // setBoard(null);
    setBoardComtList(null);
    setBoardAtchFileList(null);
    const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/board/getEventPaging`,
    params : {      pageNum : num
                  , searchKeyword : searchKeyword
                  , eventDivsCd : eventDivsCd
              }
  }
  fn_axios(param,callbackGetBoardPaging,null);
}



const callbackGetBoardPaging = (res) => {
  setBoardList(res.data.object);
  setCnt(res.data.map.cnt);
    var tempInt  = parseInt(res.data.map.cnt%pageCnt);
    if(tempInt != 0 ) {
        tempInt = parseInt(res.data.map.cnt/pageCnt) + 1;
    } else {
        tempInt = parseInt(res.data.map.cnt/pageCnt);
    }
    settingPasing(tempInt);
}

const getBoard = (num) => {
  // setBoardList(null);
  const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/board/getSlctBoard`,
    params : {    boardId : num
              }
    }
  fn_axios(param,callbackGetBoard,null);
}

const callbackGetBoard = (res) => {
  console.log(res.data.map.boardCntnList);
  setBoard(res.data.map.board);
  setBoardCntnList(res.data.map.boardCntnList);
  setBoardComtList(res.data.map.boardComtList);
  setBoardAtchFileList(res.data.map.boardAtchFileList);
}


const settingPasing = (maxNum) => {
    setPageMaxNum(parseInt(maxNum));
    // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
    var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2)  ;
    var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) +2;
    var tempList = [];

    if(maxNum != 0 ) {
        for (var i = start; i <= end; i++) {
            tempList.push(i);
        }
    }
    setPaging(tempList);
}
const onClickPaging = (e) => {
  setPageNum(parseInt(e.target.text));
  //getPointPage(e.target.text);

  return null; 
}
const onClickSearch = () => {
  if(pageNum == 1) {
    getBoardPaging(pageNum);
  } else {
    setPageNum(1);
  }
  
}

const handleDownload = async (e) => {
  try {
    const res = await fetch(boardAtchFileList[e.target.id].atchFileUrl);
    const blob = await res.blob();
    const downloadUrl = window.URL.createObjectURL(blob); // 이 과정이 필요하다.

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = boardAtchFileList[e.target.id].atchFileNm;
    link.click()
  } catch (error) {
    console.error('Error downloading file:', error);
  }
  return;
};

const handleSearchKeyword = (e) => {
  setSearchKeyword(e.target.value);
};




const deleteBoardComt = (comtOrd) => {
  const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/board/deleteBoardComt`,
    params : {    comtOrd: comtOrd
                  , boardId : board == null ? null : board.boardId
              }
    }
  fn_axios(param,callbackDeleteBoardComt,null);
}

const callbackDeleteBoardComt = (res) => {
  setBoardComtList(null);
  setBoardComtList(res.data.object);
}

const createBoardComt = () => {
  if(getCookieUserId()) {
    if(comtCntn.length < 3) {
      alert("세글자 이상 입력 바랍니다.");
      return ;
    }
    const param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/board/createBoardComt`,
      params : {      boardId : board == null ? null : board.boardId
                    , userId : getCookieUserId()
                    , comtCntn : comtCntn
                }
      }
    fn_axios(param,callbackCreateBoardComt,null);
  } else {
    alert("로그인 후 이용바랍니다.");
    return false;
  }
}

const callbackCreateBoardComt = (res) => {
  console.log(res);
  setComtCntn("");
  setBoardComtList(null);
  setBoardComtList(res.data.object);
  
}


useEffect(() => {
  getBoardPaging(pageNum);
  }, [pageNum]); 

useEffect(() => {
  console.log(eventLId);
  if(eventLId) {
    console.log(eventLId);
    getBoard(eventLId);
  }
  }, []); 

return (
  <Layout>
        {board == null ?
          <div className="contents event">
            <Slider></Slider>
            <h1 className="sub_title"  onClick={() => setBoard(null)} >이벤트*</h1>

            <section className="event_list list_type1">
                {/* <!-- list top search --> */}
                <div className="bar">
                    <div className="tx_type03">전체 <b>{cnt == null ? "0" : cnt}</b>건</div>
                    <div className="search_box">
                    <select name="state" value={eventDivsCd} onChange={handleEventDivsCd}>
                      <option value={""}>구분</option>
                      <option value={"EVENT_DIVS_CD_01"}>리그</option>
                      <option value={"EVENT_DIVS_CD_02"}>팝업</option>
                      <option value={"EVENT_DIVS_CD_03"}>첼린지</option>
                    </select>
                    <input type="text" name="keyword"  value={searchKeyword} onChange={handleSearchKeyword} placeholder="키워드"/>
                    <button onClick={onClickSearch}>검색</button>
                    </div>
                </div>
                {/* <!-- //list top search --> */}
                <div className="table_type1">
                    <table>
                    <colgroup className="pc">
                        <col style={{width: "auto"}}/>
                        <col style={{width: "22%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "5%"}}/>
                    </colgroup>
                    <colgroup className="mo">
                        <col style={{width: "auto"}}/>
                        <col style={{width: "22%"}}/>
                    </colgroup>
                    <thead>
                        <th>제목</th>
                        <th>협찬</th>
                        <th>등록일</th>
                        <th>조회수</th>
                    </thead>
                    <tbody>
                      {(boardList == null || boardList.length == 0)  ?
                      <tr>
                        <td style={{"textAlign":"center"}} colSpan={3}> 검색된 결과가 없습니다.</td>
                      </tr>
                      : boardList.map((item, index) => (
                        <tr>
                          <td><b>{item.eventDivsNm} .</b><a href="#" onClick={() => getBoard(item.boardId)}>{item.boardTitle}</a></td>
                          <td>{item.eventSpsrNm == null ? "": item.eventSpsrNm}</td>
                          <td className="date">{moment(item.regrDtm).format('YYYY-MM-DD')} </td>
                          <td>{item.slctNum}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* <!-- paging --> */}
                {boardList == null ? null : 
                    <div className="paging">
                        {paging.map((item, index) => (
                            <a href="#" key={item} onClick={onClickPaging} className={pageNum == item ? "on" :""}>{item}</a>
                        ))}
                    </div>
                }
                {/* <!-- //paging --> */}
              </section>
            </div>
              : 
            <div className="contents event">

              <div className="top_line">
                <h1 className="sub_title" onClick={() => setBoard(null)} >이벤트*</h1>
                <a href="#" onClick={() => setBoard(null)} className="btn_return pc">
                  목록으로 돌아가기
                  <span></span>
                  <span></span>
                  <span></span>
                </a>
              </div>
          
              <section className="event_contents">
          
                <div className="left_list pc">
                  <div className="search_box">
                    <select name="state" value={eventDivsCd} onChange={handleEventDivsCd}>
                      <option value={""}>구분</option>
                      <option value={"EVENT_DIVS_CD_01"}>리그</option>
                      <option value={"EVENT_DIVS_CD_02"}>팝업</option>
                      <option value={"EVENT_DIVS_CD_03"}>첼린지</option>
                    </select>
                    <input type="text" name="keyword"  value={searchKeyword} onChange={handleSearchKeyword} placeholder="키워드"/>
                    <button onClick={onClickSearch}>검색</button>
                  </div>
                  <ul>
                    {boardList == null ? null : boardList.map((item, index) => (
                      <li>
                        <span><b>{item.eventDivsNm} .</b><a href="#" onClick={() => getBoard(item.boardId)}>{item.boardTitle}</a></span>
                        <span className="date">{moment(item.regrDtm).format('YYYY-MM-DD')} </span>
                      </li>
                      ))}
                  </ul>
                </div>
          
                {/* <!-- content --> */}
                <div className="notice_content">
          
                  <div className="title"> 
                    <h2>{board.eventDivsNm} . {board.boardTitle}</h2>
                    <div className="tx_type04 pc">
                      <span>{moment(board.regrDtm).format('YYYY-MM-DD')}</span> &#47;
                      <span>조회 : {board.slctNum}</span>
                    </div>
                  </div>
           
                  {/* <!-- content info --> */}
                  <div className="info">
                    <div className="img_info">
                      {/* <img src="../images/03_event/event_img01.jpg" alt=""/> */}
                      <div className="tx_type04 mo">
                        <span>{moment(board.regrDtm).format('YYYY-MM-DD')}</span> &#47;
                        <span>조회 : {board.slctNum}</span>
                      </div>
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: board.boardCntn}}></div>
                        {boardCntnList.map((item, index) => (
                          <div style={{paddingBottom:"10px"}}>
                          {!item.boardCntnFileUrl ? null : 
                          <img src={item.boardCntnFileUrl} alt=""/>
                          }
                          <div dangerouslySetInnerHTML={{ __html: item.boardCntn}}></div>
                          </div>
                      ))}
                      </div>
                      <div className="file">
                        {boardAtchFileList == null ? null : boardAtchFileList.map((item, index) => (
                        <a href="#" onClick={handleDownload}><p id={index + ''}>첨부파일 {index}. {item.atchFileNm}</p></a>
                        ))}
                      </div>
                    </div>
                    
                    {/* <!-- comment --> */}
                      <div className="comment">
                      <div className="comment_input">
                        <input type="text" name="comment" placeholder="댓글 입력하기" value={comtCntn} onChange={handleBoardComt} maxLength={100}/>
                        <p className="input_bt" >100글자 이내로 입력이 가능합니다.</p>
                        <span>({comtCntn.length})</span>
                        <button className="btn_type1" onClick={createBoardComt}>등록하기</button>
                      </div>
                      <div className="comment_view">
                        <ul className="accordion">
                        {boardComtList == null ? null : boardComtList.map((item, index) => (
                          item.regrUserId == getCookieUserId() ? 
                            <li className="del" ><span onClick={() => deleteBoardComt(item.comtOrd)}>del</span>{item.comtCntn}</li> : <li>{item.comtCntn}</li>
                        ))}
                        </ul>
                        <div className="btn_accordion">댓글 더보기</div>
                      </div>
                    </div>
                    {/* <!-- //comment --> */}
          
                    {/* <!-- paging --> */}
                    <div className="paging_arrow">
                      {/* <a href="javascript:;" className="prev end"></a> */}
                      <a href="#" onClick={() => setBoard(null)}>목록으로 돌아가기</a>
                      {/* <a href="javascript:;" className="next"></a> */}
                    </div>
                    {/* <!-- //paging --> */}
                  </div>
                  {/* <!-- //content info --> */}
          
                </div>
                {/* <!-- //content --> */}
              </section>
            </div>
            }
      </Layout>
  );
}

export default EventList;
