import React, { useEffect, useState } from 'react';
import Layout from '../../layout/Layout.tsx';
import { fn_axios } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';

const FindId = () => {
    const [userNm, setUserNm] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userInfo, setUserInfo] = useState([]);
    const [checkMap, setCheckMap] = useState({ "userNm": false, "userPhone": false });
    const [findSuccess, setFindSuccess] = useState(false);
    const [error404, setError404] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");

    const handleIsName = (e) => {
        setCheckMap({ ...checkMap, "userNm": false });
        setUserNm(e.target.value);
    };

    const handleIsPhone = (e) => {
        setCheckMap({ ...checkMap, "userPhone": false });
        setUserPhone(e.target.value);
    };

    const checkName = () => {
        setCheckMap({ ...checkMap, "userNm": false });
        if (userNm === "") {
            return false;
        }
        return true;
    };

    const checkPhone = () => {
        setCheckMap({ ...checkMap, "userPhone": false });
        if (userPhone === "") {
            return false;
        }
        return true;
    };

    const findIdSuccessCallBack = (res) => {
        console.log(res);
        if (res && res.status === 200) {
            if (Array.isArray(res.data) && res.data.length > 0 && res.data[0].message) {
                setInfoMessage(res.data[0].message);
                setUserInfo([]);
            } else {
                const data = res.data.map(userEmail => ({
                    userEmail: emailMask(userEmail),
                }));
                setUserInfo(data);
                setInfoMessage("");
            }
            setFindSuccess(true);
            setError404(false);
            setUserNm("");
            setUserPhone("");
        } else {
            errorCallback(res);
        }
    };

    const emailMask = (userEmail) => {
        if (typeof userEmail !== 'string') {
            return userEmail;
        }
        const atIndex = userEmail.indexOf('@');
        const maskedPart = userEmail.slice(0, atIndex - 3) + "***";
        return maskedPart + userEmail.slice(atIndex);
    };

    const errorCallback = (error) => {
        console.error("Error Finding Email : ", error);
        if (error.response) {
            if (error.response.status === 404) {
                setInfoMessage(error.response.data);
                setFindSuccess(false);
                setError404(true);
                setUserNm("");
                setUserPhone("");
            } else {
                alert("서버 오류 입니다.");
            }
        } else {
            alert("서버 오류 입니다.");
        }
    };

    const onClickFindId = () => {
        const phoneRegEx = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;

        if (!checkName()) {
            alert("이름을 입력해주세요.");
            return;
        }
        if (!checkPhone()) {
            alert("전화번호를 입력해주세요.");
            return;
        }
        if (userPhone.match(phoneRegEx) === null) {
            alert("올바른 전화번호 형식이 아닙니다. \n '-'을 제외한 전화번호를 입력하세요.");
            return false;
        }
        const param = {
            method: 'POST',
            url: `${API.BACKEND}/v1/user/findId`,
            params: { userNm: userNm, userPhone: userPhone }
        };
        fn_axios(param, findIdSuccessCallBack, errorCallback);
    };

    useEffect(() => {
        // userInfo가 변경되었을 때 실행할 로직을 여기에 추가할 수 있습니다.
    }, [userInfo]);

    return (
        <Layout>
            <div className='contents find'>
                <h1>아이디 찾기</h1>
                <section className='default_find'>
                    <div className='form'>
                        <input type="text" value={userNm} onChange={handleIsName} placeholder="이름" />
                        <input type="text" value={userPhone} onChange={handleIsPhone} placeholder="전화번호" />
                    </div>
                    <button className='btn_type1 arrow' onClick={onClickFindId}>아이디 찾기</button>
                    <div className='find_id'>&nbsp; &nbsp;
                        {findSuccess && userInfo.length > 0 &&
                            <div>
                                <strong>찾은 회원 아이디 목록</strong>
                                <ul>
                                    {userInfo.map((user, index) => (
                                        <li key={index}>{user.userEmail}</li>
                                    ))}
                                </ul>
                            </div>
                        }
                        {infoMessage && <p>{infoMessage}</p>}
                        {error404 && <strong>회원정보가 없습니다.</strong>}
                    </div>
                    <div className='mine' style={{ marginBottom: "10px" }}>
                        <a href='/user/login'>로그인 화면으로 이동</a>
                    </div>
                </section>
                <div className="tx_type02 pc"><b>당신이 원하는 대회를 원하는 장소에서</b>당신의 대회를 알리고 싶다면, <b>WOORI GROUND!</b> WOOG ME! CONTACT ME!</div>
            </div>
        </Layout>
    );
}

export default FindId;
