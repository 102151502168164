import React from 'react';
import {useState,useEffect } from 'react';
import Layout from '../../layout/Layout.tsx';
import '../../assets/css/AgeConfirmButton.css';
import {API} from '../../config/config.tsx';
import { useNavigate } from 'react-router-dom';


function AgeConfirm() {
	const navigate = useNavigate();
	const [isAge, setAge] = useState<String>("Y");
	const openPassAuth = (isAge) => {
		openPassAuthCallback(isAge);
		if (window.innerWidth <= 768) {
			window.MOBILEOK.process(`${API.BACKEND}/v1/pass/request?type=mobile&isAge=${isAge}`, "WB", ""); // 모바일 크기
		  } else {
			setAge(isAge);
			window.MOBILEOK.process(`${API.BACKEND}/v1/pass/request?type=popup&isAge=${isAge}`, "WB", "callBackFunc");// 데스크탑 크기
		  }
	}


	const openPassAuthCallback = ( isAge) => {
		const script = document.createElement('script');
		var funcScript ;
		if("Y" == isAge) {
			funcScript = "function callBackFunc(result) {" +
			"try {" +
				// "document.querySelector('#result').value = JSON.parse(result, null, 4);" +
				"document.querySelector('#userName').value = JSON.parse(result, null, 4).userName;" +
				"document.querySelector('#userPhone').value = JSON.parse(result, null, 4).userPhone;" +
				"document.querySelector('#userGender').value = JSON.parse(result, null, 4).userGender == '1' ? 'MAN' : 'WOMAN';" +
				"document.querySelector('#userBirthday').value = JSON.parse(result, null, 4).userBirthday.substr(0,4) + '-'+ JSON.parse(result, null, 4).userBirthday.substr(4,2) + '-'+ JSON.parse(result, null, 4).userBirthday.substr(6,2);" +
				"document.querySelector('#isPass').value = 'true';" +
				"document.querySelector('#isAge').value = '" + isAge + "';" +
			"} catch (error) {" +
				"alert(error);" +
			"}" +
			"}";
		} else {
			funcScript = "function callBackFunc(result) {" +
			"try {" +
				// "document.querySelector('#result').value = JSON.parse(result, null, 4);" +
				"document.querySelector('#adertPhone').value = JSON.parse(result, null, 4).userPhone;" +
				"document.querySelector('#adertBrithday').value = JSON.parse(result, null, 4).userBirthday.substr(0,4) + '-'+ JSON.parse(result, null, 4).userBirthday.substr(4,2) + '-'+ JSON.parse(result, null, 4).userBirthday.substr(6,2);" +
				"document.querySelector('#isPass').value = 'true';" +
				"document.querySelector('#isAge').value = '" + isAge + "';" +
			"} catch (error) {" +
				"alert(error);" +
			"}" +
			"}";
		}
		const callBackFunc = document.createTextNode(funcScript);

		script.appendChild(callBackFunc);
		document.body.appendChild(script);
	}

	useEffect(() => {
		const intervalId = setInterval(() => {
			if(document != null && document.querySelector('#isPass').value == 'true') {

				var passParam = {
					p_userName : document.querySelector('#userName').value ,
					p_userPhone : document.querySelector('#userPhone').value ,
					p_userGender : document.querySelector('#userGender').value ,
					p_userBirthday : document.querySelector('#userBirthday').value ,
					p_isAge : document.querySelector('#isAge').value ,
					p_adertPhone : document.querySelector('#adertPhone').value ,
					p_adertBrithday : document.querySelector('#adertBrithday').value 
				};
				navigate('/user/joinAgree', {state : passParam});
			}
		}, 1000); // 매 1초마다 현재 시간 업데이트
	
		// 컴포넌트가 언마운트될 때 인터벌을 정리
		return () => clearInterval(intervalId);
	  }, []);



	return (
		<Layout>
			<div className="container">
				<h1>회원가입 본인 인증</h1>
				<div className="button-container">
					<button className="verification-button" onClick={() => openPassAuth("Y")}>14세 이상 본인인증</button>
					<button className="verification-button" onClick={() => openPassAuth("N")}>14세 미만 보호자 인증</button>
				</div>
				<div> 
					<input id="userName" hidden={true}></input>
					<input id="userPhone" hidden={true}></input>
					<input id="userGender" hidden={true}></input>
					<input id="userBirthday" hidden={true}></input>
					<input id="isPass" hidden={true}></input>
					<input id="isAge" hidden={true}></input>
					<input id="adertPhone" hidden={true}></input>
					<input id="adertBrithday" hidden={true}></input>
				</div>
			</div>
		</Layout>
    );
  }
		
  export default AgeConfirm;