import React from "react";
import {useState,useEffect,} from 'react';
import moment from "moment";

import {fn_axios,fn_post_fileUpload_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";
import Slider from "../../layout/Slider.tsx";
import { useLocation } from 'react-router-dom';


function BoardList() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [isInit, steIsInit] = useState(true);


  const [boardList, setBoardList] = useState([]);
  const [board, setBoard] = useState(queryParams.get('boardId'));
  const bnnrLId = useState(queryParams.get('bnnrLId'));
  const [boardAtchFileList, setBoardAtchFileList] = useState([]);
  const [boardComtList, setBoardComtList] = useState([]);
  const [boardCntnList, setBoardCntnList] = useState([]);
  const [comtCntn, setComtCntn] = useState("");
  const [qustCntn, setQustCntn] = useState("");
  const [qustList, setQustList] = useState([]);
  const [isPopup, setIsPopup] = useState(true);

  const [pageNum, setPageNum] = useState(1);
  const [pageMaxNum, setPageMaxNum] = useState(0);
  const [pageCnt, setPageCnt] = useState(10);
  const [paging, setPaging] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");


  const handleBoardComt = (e) => {
    setComtCntn(e.target.value);
  }
  const handleQust = (e) => {
    setQustCntn(e.target.value);
  }
  const handleIsPopup = () => {
    setIsPopup(!isPopup);
  }


  const getBoardPaging = (num) => {
    setBoard(null);
    setBoardComtList(null);
    setBoardAtchFileList(null);
    const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/board/getBoardPaging`,
    params : {      pageNum : num
                  , searchKeyword : searchKeyword
                  , boardDivsCd : 'BOARD_DIVS_CD_01'
              }
  }
  fn_axios(param,callbackGetBoardPaging,null);
}



const callbackGetBoardPaging = (res) => {
  setBoardList(res.data.object);
    var tempInt  = parseInt(res.data.map.cnt%pageCnt);
    if(tempInt != 0 ) {
        tempInt = parseInt(res.data.map.cnt/pageCnt) + 1;
    } else {
        tempInt = parseInt(res.data.map.cnt/pageCnt);
    }
    settingPasing(tempInt);
}

const getBoard = (num) => {
  setBoardList(null);
  setQustList(null);
  const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/board/getSlctBoard`,
    params : {    boardId : num,
      bnnrLId : bnnrLId
              }
    }
  fn_axios(param,callbackGetBoard,null);
}

const callbackGetBoard = (res) => {
  console.log(res);
  setBoard(res.data.map.board);
  setBoardCntnList(res.data.map.boardCntnList);
  setBoardComtList(res.data.map.boardComtList);
  setBoardAtchFileList(res.data.map.boardAtchFileList);
}


const settingPasing = (maxNum) => {
    setPageMaxNum(parseInt(maxNum));
    // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
    var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2)  ;
    var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) +2;
    var tempList = [];

    if(maxNum != 0 ) {
        for (var i = start; i <= end; i++) {
            tempList.push(i);
        }
    }
    setPaging(tempList);
}
const onClickPaging = (e) => {
  setPageNum(parseInt(e.target.text));
  //getPointPage(e.target.text);

  return null; 
}
const onClickSearch = () => {
  if(pageNum == 1) {
    getBoardPaging(pageNum);
  } else {
    setPageNum(1);
  }
  
}

const handleDownload = async (e) => {
  try {
    const res = await fetch(boardAtchFileList[e.target.id].atchFileUrl);
    const blob = await res.blob();
    const downloadUrl = window.URL.createObjectURL(blob); // 이 과정이 필요하다.

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = boardAtchFileList[e.target.id].atchFileNm;
    link.click()
  } catch (error) {
    console.error('Error downloading file:', error);
  }
  return;
};

const handleSearchKeyword = (e) => {
  setSearchKeyword(e.target.value);
};

const loadingBoardTitle = () => {
  return <tr>
          <td className="tit">{board.boardTitle}</td>
          <td className="date">{moment(board.regrDtm).format('YYYY-MM-DD')}</td>
          <td>{board.slctNum}</td>
        </tr>
}

const loadingBoard = () => {
  // return <div className="accordion" dangerouslySetInnerHTML={board.boardCntn}>
  return (<div>
            <div  className="accordion"  >
              <div dangerouslySetInnerHTML={{ __html: board.boardCntn}}></div>
              {boardCntnList.map((item, index) => (
                <div dangerouslySetInnerHTML={{ __html: item.boardCntn}}></div>
            ))}
            </div>
            <div className="btn_accordion mo">상세내용 더보기</div>
            <div className="file">
              {boardAtchFileList == null ? null : boardAtchFileList.map((item, index) => (
              <a href="#" onClick={handleDownload}><p id={index + ''}>첨부파일 {index}. {item.atchFileNm}</p></a>
              ))}
            </div>
          </div>
  );
}

const loadingBoardComt = () => {
  return (
    <div>
      <div className="comment">
      <div className="comment_input">
        <input type="text" name="comment" placeholder="댓글 입력하기" value={comtCntn} onChange={handleBoardComt} maxLength={100}/>
        <p className="input_bt" >100글자 이내로 입력이 가능합니다.</p>
        <span>({comtCntn.length})</span>
        <button className="btn_type1" onClick={createBoardComt}>등록하기</button>
      </div>
      <div className="comment_view">
        <ul className="accordion">
        {boardComtList == null ? null : boardComtList.map((item, index) => (
          item.regrUserId == getCookieUserId() ? 
            <li className="del" ><span onClick={() => deleteBoardComt(item.comtOrd)}>del</span>{item.comtCntn}</li> : <li>{item.comtCntn}</li>
        ))}
        </ul>
        <div className="btn_accordion">댓글 더보기</div>
      </div>
    </div>
    <div className="paging_arrow">
      <a href="#" onClick={backList}>목록으로 돌아가기</a>
    </div>
  </div>
  );
}

const backList = () => {
  getBoardPaging(pageNum);
  getQust();
}


const deleteBoardComt = (comtOrd) => {
  const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/board/deleteBoardComt`,
    params : {    comtOrd: comtOrd
                  , boardId : board == null ? null : board.boardId
              }
    }
  fn_axios(param,callbackDeleteBoardComt,null);
}

const callbackDeleteBoardComt = (res) => {
  setBoardComtList(null);
  setBoardComtList(res.data.object);
}

const createBoardComt = () => {
  if(getCookieUserId()) {
    const param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/board/createBoardComt`,
      params : {      boardId : board == null ? null : board.boardId
                    , userId : getCookieUserId()
                    , comtCntn : comtCntn
                }
      }
    fn_axios(param,callbackCreateBoardComt,null);
  } else {
    alert("로그인 후 이용바랍니다.");
    return false;
  }
}

const callbackCreateBoardComt = (res) => {
  console.log(res);
  setComtCntn("");
  setBoardComtList(null);
  setBoardComtList(res.data.object);
}

const createQust = () => {
  if(getCookieUserId()) {
    const param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/qust/createQustAnsw`,
      params : {      userId : getCookieUserId()
                    , qustCntn : qustCntn
                }
      }
    fn_axios(param,callbackCreateQust,null);
  } else {
    alert("로그인 후 이용바랍니다.");
    return false;
  }
}

const callbackCreateQust = (res) => {
  if(res.status) {
    setQustList(res.data.object)
    setQustCntn(null);
    handleIsPopup();
  } else {
    alert("정상적으로 등록되지 않았습니다.");
  }
}

const getQust = () => {
  const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/qust/getQustAnsw`,
    params : {     userId : getCookieUserId()
              }
    }
  fn_axios(param,callbackGetQust,null);
}

const callbackGetQust = (res) => {
  setQustList(res.data.object);
}

useEffect(() => {
  if(queryParams.get('boardId') != null 
    && queryParams.get('boardId') != ""
    && isInit ){
      steIsInit(false);
      getBoard(queryParams.get('boardId'));
  } else {
    getBoardPaging(pageNum);
  }
  
}, [pageNum]); 

useEffect(() => {
  getQust();
  }, []); 

return (
  <Layout>
      <div className="contents cs_notice">

          <div className="top_line">
            <h1 className="sub_title" onClick={() => window.location.href = "/"}>고객센터 / 공지사항</h1>
            {/* <a href="#" className="btn_return pc">
              목록으로 돌아가기
              <span></span>
              <span></span>
              <span></span>
            </a> */}
          </div>

          <section className="cs_notice_list">

            <div className="left_text pc">우리그라운드 <br/> 회원님들께 <br/> 알려드립니다.</div>

            {/* <!-- content --> */}
            <div className="notice_content">
              <div className="info">
                <div className="search_box">
                  <input type="text" name="title_search" value={searchKeyword} onChange={handleSearchKeyword} />
                  <button className="" onClick={onClickSearch}>제목 검색</button>
                  <a href="#" onClick={handleIsPopup}>1:1 문의하기</a>
                </div>

                <div className="qna">
                {qustList == null ? null : qustList.map((item, index) => (
                        <div>
                          <div className="question">
                            <span className="tit">질문{index + 1} : </span>
                            <span>
                              {item.qustCntn}
                            </span>
                            <p className="date">{moment(item.qustDtm).format('YYYY-MM-DD')} </p>
                          </div>
                          {item.answCntn == null ? null :
                          <div className="answer">
                            <span className="tit">답변{index + 1}  : </span>
                            {item.answCntn}
                            <p className="date">{moment(item.answDtm).format('YYYY-MM-DD')} </p>
                          </div>
                          }
                      </div>
                      ))}
                </div>

                <div className="table_type1 default">
                  <table>
                    <colgroup className="pc">
                      <col style={{ width: "auto" }} />
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "7%" }} />
                    </colgroup>
                    <colgroup className="mo">
                      <col style={{ width: "auto" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "16%" }} />
                    </colgroup>
                    <thead>
                      <th>제목</th>
                      <th>등록일</th>
                      <th>조회수</th>
                    </thead>
                    <tbody>
                      {(boardList == null || boardList.length == 0)  ?
                      <tr>
                        <td style={{"textAlign":"center"}} colSpan={3}> 검색된 결과가 없습니다.</td>
                      </tr>
                       : boardList.map((item, index) => (
                          <tr>
                            <td><a href="#" onClick={() => getBoard(item.boardId)}>{item.boardTitle}</a></td>
                            <td className="date">{moment(item.regrDtm).format('YYYY-MM-DD')} </td>
                            <td>{item.slctNum}</td>
                          </tr>
                      ))}
                      {board == null ? null : loadingBoardTitle()}
                    </tbody>
                  </table>
                  {board == null ? null : loadingBoard()}
                </div>
                {board == null ? null : loadingBoardComt()}
              

                {boardList == null ? null : 
                <div className="paging">
                    {paging.map((item, index) => (
                        <a href="#" key={item} onClick={onClickPaging} className={pageNum == item ? "on" :""}>{item}</a>
                    ))}
                </div>
                }
              
                {/* <!-- popup --> */}
                {isPopup ? null : 
                <div className="dim_layer">
                  <div className="popup_layer">
                    <div className="btn_close">
                      <a href="#" onClick={handleIsPopup}>닫기</a>
                    </div>
                    <div className="tit"><span className="bt_line">1:1 문의하기</span></div>
                    <p>궁금하신 내용을 200자 이내로 작성하여 등록하시면 빠른시간 내에 답변 드리도록 하겠습니다.</p>
                    <textarea name="ask" value={qustCntn} maxLength={200} onChange={handleQust} ></textarea>
                    <div className="bt_area">
                      <span className="num">({qustCntn.length})</span>
                      <button className="btn_type1" onClick={createQust}>문의사항 등록하기</button>
                    </div>
                  </div>
                </div>
                }
                {/* <!-- //popup --> */}
              </div>

            </div>
            {/* <!-- //content --> */}
          </section>
          <Slider></Slider> 
        </div>
      </Layout>
  );
}

export default BoardList;
