import React from "react";
import moment from "moment";
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import TeamSelector from "../../common/TeamSelector.tsx";

import {fn_axios,fn_post_fileUpload_axios,getCookieUserId, CONSTANTS} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";

import KiwoomPay from "../../common/KiwoomPay.tsx";

function UserReqCmptUpdate() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cmptLId = queryParams.get('cmptLId');
  const gameKindNm = queryParams.get('gameKindNm');
  const reqId = queryParams.get('reqId');
  const payId = queryParams.get('payId');
  const [popup, setPopup] = useState();
  const [cmpt, setCmpt] = useState(null);
  const [cmptGym, setCmptGym] = useState([]);
  const [user, setUser] = useState(null);
  const [isPay, setIsPay] = useState(false);
  const [payParam, setPayParam] = useState({});
  const [EnterReqList, setEnterReqList] = useState([]);
  const [fare, setFare] = useState(0);
  const [usePoint, setUsePoint] = useState(0);
  const [confirmPoint, setConfirmPoint] = useState(0);

  const gameDivsValueMap = new Map();

  const openTeamPopup = (propCmptGameId, propEnterReqId,enterTeamNo) => {
    setPopup(<TeamSelector propCmpt={cmpt} propCmptGameId={propCmptGameId} propEnterReqId={propEnterReqId} propTeamNo={enterTeamNo} onSelectedValuesChange={callbackOpenPartnerPopup}></TeamSelector>);
  }
  const callbackOpenPartnerPopup = () =>{
    setPopup(null);
    getEnterReq();
  }

  const getCmpt = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/cmpt/getCmpt`,
        params: {cmptLId : cmptLId},
      };
      fn_axios(param, callbackGetCmpt, null);
  }
  const callbackGetCmpt = (res) => {  
    // setSignguDataList(resList);
    setCmpt(res.data.map.cmpt[0]);
    setCmptGym(res.data.map.cmptGym);
  }
  const getUser = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/user/getUser`,
        params: {userId : getCookieUserId()},
      };
      fn_axios(param, callbackGetUser, null);
  }
  const callbackGetUser = (res) => {  
    // setSignguDataList(resList);
    setUser(res.data.object);
  }

  const getEnterReq = () => {
    const param = {
      method : 'GET',
      url : `${API.BACKEND}/${API.VERSION}/req/getEnterReq`,
      params : {    cmptLId: cmptLId
                    // , cmptGameId : cmptGameId
                    , reqUserId : getCookieUserId()
                    , payId : payId
                    , reqRsltCd : 'REQ_RSLT_CD_02'
                }
      }
    fn_axios(param,callbackGetEnterReq,null);
  }
    
  const callbackGetEnterReq = (res) => {
    // console.log(res);
    setEnterReqList(res.data.object);
    var list = res.data.object;
    var tempFare = 0;
    for(var i = 0 ; i < list.length ; i++ ) {
      tempFare += list[i].enterFare;
    }
    setFare(tempFare);
  }


  const clickPoint = () => {
    setConfirmPoint(usePoint);
  }

  const createGameDvisValue = () => {
    let keyList = Array.from(gameDivsValueMap.keys());
    let tempList = [];
    // if(cmpt != null && new Date(cmpt.cmptReqEndDtm) < new Date()) {
    //   alert("참가신청이 종료되었습니다");
    //   return false;
    // } 

    if(keyList != null && keyList.length > 0) {
      for(var i = 0 ; i < keyList.length ; i++ ) {
        if(gameDivsValueMap.get(keyList[i]) == "") {
          alert("값이 입력되지 않았습니다.");
          if(document.getElementById(keyList[i]) !=null) document.getElementById(keyList[i]).focus();
          return;
        } else {
          // 키값에 | 가 있는경우 리스트임.
          if(keyList[i].split("|").length > 1) {
            tempList.push({"enterReqId":keyList[i].split("_")[0],"gameDivsLId":gameDivsValueMap.get(keyList[i]),"cmptReqGameDivsValue":"","userId":getCookieUserId()});
          } else {
            tempList.push({"enterReqId":keyList[i].split("_")[0],"gameDivsLId":keyList[i].split("_")[1],"cmptReqGameDivsValue":gameDivsValueMap.get(keyList[i]),"userId":getCookieUserId()});
          }
        }
      }
      // console.log(tempList);
      // return ;
  
      const param = {
        method : 'POST',
        url : `${API.BACKEND}/${API.VERSION}/req/createGameDvisVlalue`,
        params : {      gameDivsValueList : tempList
                  }
        }
        fn_axios(param,callbackCreateGameDvisValue,null);
    } else {
      // moveUserPayDetail();
      moveUserReqDetail();
    }
  }

  const callbackCreateGameDvisValue = (res) => {
    if(res.data.status) {
      // moveUserPayDetail();
      moveUserReqDetail();
    } else {
      alert(res.data.message);
    }
  }

  const moveUserReqDetail = () => {
    window.location.href = `/user/userReqDetail?cmptLId=${cmptLId}&payId=${payId}&gameKindNm=${gameKindNm}`;
  }
  const moveUserPayDetail = () => {
    window.location.href = `/user/userPayDetail?cmptLId=${cmptLId}&payId=${payId}&gameKindNm=${gameKindNm}`;
  }
  const cssGameDivInput = {
    background: "#fff" ,
    border: "1px solid #aaa",
    height: "28px",
    maxWidth: "130px",
    textAlign: "center",
    fontSize: "1.6rem",
    color: "#111",
    letterSpacing: "-0.01em"
  };
  const checkGameDivsComplet = (gameClsfKindCd,gameDivsLValue,gameDivsLId , enterReqId, gameDivsNm,cmptReqGameDivsValue) => {
    if(gameClsfKindCd  != null && (gameClsfKindCd == "GAME_CLSF_KIND_CD_03" || gameClsfKindCd == "GAME_CLSF_KIND_CD_04" || gameClsfKindCd == "GAME_CLSF_KIND_CD_05" || gameClsfKindCd == "GAME_CLSF_KIND_CD_06")) {
      // 여부 일때는 우선적으로 N 부터
      let tempList = [];
      let tempList2 = [];

      gameDivsValueMap.set(enterReqId + "_" + gameDivsLId,cmptReqGameDivsValue);
      if(gameClsfKindCd == "GAME_CLSF_KIND_CD_05" || gameClsfKindCd == "GAME_CLSF_KIND_CD_06") {
        tempList = gameDivsLValue.split('|');
        tempList2 = gameDivsLId.split('|');
      }
      
      return (
        <div style={{marginTop:"5px"}}>
            <b>{gameDivsNm + " "}</b>
            <span >
            {gameClsfKindCd == "GAME_CLSF_KIND_CD_03" ? 
            <input maxLength={100} id={`${enterReqId}_${gameDivsLId}`} style={cssGameDivInput} onChange={(event) => gameDivsValueMap.set(enterReqId + "_" + gameDivsLId,event.target.value)} defaultValue={cmptReqGameDivsValue}/> 
            :
            null 
            }
            {gameClsfKindCd== "GAME_CLSF_KIND_CD_04" ?  
            <select style={{textAlign:"center"}} id={`${enterReqId}_${gameDivsLId}`} onChange={(event) => gameDivsValueMap.set(enterReqId + "_" + gameDivsLId,event.target.value)} defaultValue={cmptReqGameDivsValue}>
              <option  value="Y" selected={gameDivsLValue == 'Y'} >Y</option>
              <option  value="N" selected={gameDivsLValue == 'N'}>N</option>
            </select>
            :
            null 
            }
            
            {gameClsfKindCd == "GAME_CLSF_KIND_CD_05" ? 
            <select style={{textAlign:"center"}} id={`${enterReqId}_${gameDivsLId}`} onChange={(event) => gameDivsValueMap.set(enterReqId + "_" + gameDivsLId,event.target.value)} >
              {tempList.length == 0 ? null : tempList.map((item, index) => (
                <option  value={tempList2[index]} selected={item == cmptReqGameDivsValue ? true : false}>{item}</option>
              ))}
            </select>
            :
            null 
            }
            {gameClsfKindCd == "GAME_CLSF_KIND_CD_06" ? 
            <select disabled style={{textAlign:"center"}} id={`${enterReqId}_${gameDivsLId}`} onChange={(event) => gameDivsValueMap.set(enterReqId + "_" + gameDivsLId,event.target.value)} >
              {tempList.length == 0 ? null : tempList.map((item, index) => (
                <option  value={tempList2[index]} selected={item == cmptReqGameDivsValue ? true : false}>{item}</option>
              ))}
            </select>
            :
            null 
            }

            </span>
        </div>
      );
    } else {
      return ;
    }

  }


  useEffect(() => {
    if(!getCookieUserId()){
      window.location.href = `/cmpt/cmptDetail?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}`;
    }
    if(cmpt == null) {
      getUser();
      getCmpt();
      getEnterReq();
    }
      
  }, []); 


  
  return (
    <Layout>

  <div className="contents contest">

    <div className="top_line">
      <h1 className="sub_title pc" onClick={() => window.location.href = `/user/userReqDetail?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}`}>신청내역 상세 돌아가기</h1>
      <h1 className="sub_title mo">대회참가 / {cmpt == null ? null : cmpt.gameKindNm}</h1>
      <a href="#" onClick={() => window.location.href = `/user/userReqDetail?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}`} className="btn_return pc">
        상세 돌아가기
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>

    <section className="application payment">
      {/* <!-- menu tab & poster --> */}

      {/* <!-- mo ver title --> */}
      <h2 className="mo"><span>{cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
            </span>&nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
      {/* <!-- //mo ver title --> */}

      <div className="sub_menu">
        <div className="tab">
          {/* TODO : 공연 임시 */}
        {cmpt != null && cmpt.gameKindCd == CONSTANTS.GAME_KIND_0075 ? 
          <>
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=main&gameKindNm="+gameKindNm} >공연 정보</a>
          </>
          :
          <>
            <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=main&gameKindNm="+gameKindNm} >대회요강</a>
              <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=game&gameKindNm="+gameKindNm} >세부종목</a>
              <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=notice&gameKindNm="+gameKindNm} >알림/문의</a>
              <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=item&gameKindNm="+gameKindNm} >경품/기념품</a>
          </>
        }
        </div>
        <div className="tit">세부 종목 수정</div>
        <div className="poster">
        <img src={cmpt == null ? null : cmpt.cmptPcAtchFileUrl} alt={cmpt == null ? null : cmpt.cmptPcAtchFileNm} />
        </div>
      </div>
      {/* <!-- //menu tab & poster --> */}

      {/* <!-- content --> */}
      <div className="application_content">
        {/* <!-- pc ver title --> */}
        <div className="title pc"> 
          <h2><span>
          {cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
          </span>
          &nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
          <div className="tx_type04">
            <span>{cmpt == null ? null : cmpt.regrDtm}</span> &#47;
            <span>조회 : {cmpt == null ? null : cmpt.slctNum}</span> &#47;
            <span><b>참가 : {cmpt == null ? 0 : cmpt.cmptEnterPerson}</b></span>  
          </div>
        </div>
        {/* <!-- //pc ver title --> */}

        {/* <!-- content info --> */}
        <div className="info step3">
          {/* <!-- select --> */}
          <div className="search_box">
            <div className="tit">
              <b>세부 종목 및 팀명 수정</b>
            </div>
          </div>
          {/* <!-- //select --> */}
          
          <div className="list">
            <ul>
              <li>
                <span className="tit">{cmpt != null && cmpt.gameKindCd == CONSTANTS.GAME_KIND_0075 ? "공연명" :"대회명"}</span>
                <span><b>{cmpt == null ? null : cmpt.cmptLNm}</b></span>
              </li>
              <li>
                <span className="tit">기간</span>
                <span>{cmpt == null ? null : moment(cmpt.cmptStrDt).format('YYYY-MM-DD')+ " ~ " + moment(cmpt.cmptEndDt).format('YYYY-MM-DD')}</span>
              </li>
              <li>
                <span className="tit">개최장소</span>
                {cmptGym == null ?  null : cmptGym.map((item, index) => (
                <span  style={{cursor:"POINTER",textDecoration:"underline"}}onClick={() => window.location.href = `/gym/gymDetail?gymId=${item.gymId}`}>{item.gymNm }</span>
                ))}
              </li>
            </ul>
            <ul>
              <li>
                <span className="tit">이름</span>
                <span><b>{user == null ? null : user.userNm}</b></span>
              </li>
              <li>
                <span className="tit">생년월일</span>
                <span>{user == null ? null : user.userBirth.substring(0,4) + "년 " + user.userBirth.substring(4,6) + "월 " + user.userBirth.substring(6,8) +"일"}</span>
              </li>
              <li>
                <span className="tit">주소</span>
                <span>{user == null ? null : user.userAddr + " " + user.userDtlAddr}</span>
              </li>
              <li>
                <span className="tit">전화번호</span>
                <span>{user == null ? null : user.userPhone.substring(0,3) + "-" + user.userPhone.substring(3,7) + "-" + user.userPhone.substring(7,12)}</span>
              </li>
              <li>
                <span className="tit">이메일</span>
                <span>{user == null ? null : user.userEmail}</span>
              </li>
              <li className="details">
                <span className="tit">세부종목</span>
                <div className="detail_box" style={{padding:"20px; 10px;"}}>
                  <span className="tit mo" >세부종목</span>
                  {EnterReqList == null ? null : EnterReqList.map((item, index) => (
                  <div  key={index + "req"}>
                    <div>
                      <p className="first_line">
                      <span style={{"width" : "40%"}}>{item.gameLNm}</span>
                      {item.gameDivsLValue0 == null || item.gameClsfKindCd0 == 'GAME_CLSF_KIND_CD_05'  || item.gameClsfKindCd0 == 'GAME_CLSF_KIND_CD_06' ? null : <span style={{"width" : "18%"}}>{item.gameDivsLValue0}</span>}
                      {item.gameDivsLValue1 == null || item.gameClsfKindCd1 == 'GAME_CLSF_KIND_CD_05'  || item.gameClsfKindCd1 == 'GAME_CLSF_KIND_CD_06' ? null : <span style={{"width" : "20%"}}>{item.gameDivsLValue1}</span>}
                      {/* {item.gameDivsLValue2 == null ? null : <span>{item.gameDivsLValue2}</span>}/ */}
                        <span style={{"width" : "30%"}} className="pay1">금액:{item.enterFare}</span>
                      </p>
                      {item.teamYn == 'N' && item.prtnYn == "N" ?
                      null 
                      :
                      <p className="tx_type05">{item.teamYn == 'N' ? "" :  <td><button className="btn_type1 popup_open" onClick={() => openTeamPopup(item.cmptGameId, item.enterReqId,item.enterTeamNo)}>{item.enterTeamNm == null ? "팀 선택" : "팀명 : " + item.enterTeamNm}</button></td> }  <br/>
                      {item.prtnYn != "Y" ?   ""  : "파트너 : " + item.prtnUserNm.slice(0, -1) + ' * '}
                      </p> 
                      }
                    </div>
                    <div >
                      {checkGameDivsComplet(item.gameClsfKindCd0,item.gameDivsLValue0,item.gameDivsLId0,item.enterReqId, item.gameDivsNm0, item.cmptReqGameDivsValue0 || item.gameDivsLValue0)}
                      {checkGameDivsComplet(item.gameClsfKindCd1,item.gameDivsLValue1,item.gameDivsLId1,item.enterReqId, item.gameDivsNm1, item.cmptReqGameDivsValue1 || item.gameDivsLValue1)}
                      {checkGameDivsComplet(item.gameClsfKindCd2,item.gameDivsLValue2,item.gameDivsLId2,item.enterReqId, item.gameDivsNm2, item.cmptReqGameDivsValue2 || item.gameDivsLValue2)}
                      {checkGameDivsComplet(item.gameClsfKindCd3,item.gameDivsLValue3,item.gameDivsLId3,item.enterReqId, item.gameDivsNm3, item.cmptReqGameDivsValue3 || item.gameDivsLValue3)}
                      {checkGameDivsComplet(item.gameClsfKindCd4,item.gameDivsLValue4,item.gameDivsLId4,item.enterReqId, item.gameDivsNm4, item.cmptReqGameDivsValue4 || item.gameDivsLValue4)}
                      {checkGameDivsComplet(item.gameClsfKindCd5,item.gameDivsLValue5,item.gameDivsLId5,item.enterReqId, item.gameDivsNm5, item.cmptReqGameDivsValue5 || item.gameDivsLValue5)}
                      {checkGameDivsComplet(item.gameClsfKindCd6,item.gameDivsLValue6,item.gameDivsLId6,item.enterReqId, item.gameDivsNm6, item.cmptReqGameDivsValue6 || item.gameDivsLValue6)}
                      {checkGameDivsComplet(item.gameClsfKindCd7,item.gameDivsLValue7,item.gameDivsLId7,item.enterReqId, item.gameDivsNm7, item.cmptReqGameDivsValue7 || item.gameDivsLValue7)}
                      {checkGameDivsComplet(item.gameClsfKindCd8,item.gameDivsLValue8,item.gameDivsLId8,item.enterReqId, item.gameDivsNm8, item.cmptReqGameDivsValue8 || item.gameDivsLValue8)}
                      {checkGameDivsComplet(item.gameClsfKindCd9,item.gameDivsLValue9,item.gameDivsLId9,item.enterReqId, item.gameDivsNm9, item.cmptReqGameDivsValue9 || item.gameDivsLValue9)}
                    </div>
                  </div>
                ))}
                </div>
              </li>
            </ul>
          </div>

          <p className="tx_type07">위와 같은 내용으로 세부종목을 수정하시겠습니까 ?</p>
          <button className="btn_application okay" onClick={createGameDvisValue}>세부종목 <br/> 수정하기</button>

        </div>
        {/* <!-- //content info --> */}
        {popup}
      </div>
      {/* <!-- //content --> */}
    </section>
    {!isPay ? null : <KiwoomPay param={payParam}> </KiwoomPay>}
  </div>
  </Layout>
  );
}
export default UserReqCmptUpdate;