import React, { useEffect, useState } from 'react';
import Layout from '../../layout/Layout.tsx';
import { fn_axios } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';

const FindPwd = () => {
    const [userNm, setUserNm] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [resetSuccess, setResetSuccess] = useState(false);
    const [error404, setError404] = useState(false);
    const [checkMap, setCheckMap] = useState({ "userNm": false, "userEmail": false });
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");

    const handleIsNm = (e) => {
        setCheckMap({ ...checkMap, userNm: false });
        setUserNm(e.target.value);
    }

    const handleIsEmail = (e) => {
        setCheckMap({ ...checkMap, userEmail: false });
        setUserEmail(e.target.value);
    }

    const checkName = () => {
        setCheckMap({ ...checkMap, userNm: false });
        if (userNm === "") {
            return false;
        }
        return true;
    }

    const checkEmail = () => {
        const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
        setCheckMap({ ...checkMap, userEmail: false });
        if (userEmail === "") {
            return false;
        } else if (userEmail.match(emailRegEx) === null) {
            alert("이메일형식이 아닙니다.");
            return false;
        }
        return true;
    }

    const findPwdSuccessCallback = (res) => {
        console.log(res);
        if (res.status === 200) {
            if (Array.isArray(res.data) && res.data.length > 0 && res.data[0].message) {
                setInfoMessage(res.data[0].message);
                setSuccessMessage("");
                setError404(false);
            } else {
                const param = {
                    method: 'POST',
                    url: `${API.BACKEND}/v1/user/resetPwd`,
                    params: { userNm: userNm, userEmail: userEmail }
                };
                fn_axios(param, resetSuccessCallback, mailErrorCallBack);
            }
        }
    }
    const resetSuccessCallback = (res) => {
        console.log(res)
        if (res.status == 200) {
            setResetSuccess(true);
            setError404(false);
            setSuccessMessage("입력한 이메일로 비밀번호를 보냈습니다.");
        }
    }
    const mailErrorCallBack = (error) => {
        console.error("Error Mail sent FAIL : ", error);
        if ( error.response && error.response.status === 500) {
            setError404(true);
            setResetSuccess(false);
            setErrorMessage("메일 전송에 실패하였습니다.");
        }
    }

    const errorCallback = (error) => {
        console.error("Error finding password : ", error);
        if (error.response && error.response.status === 404) {
            setError404(true);
            setResetSuccess(false);
            setErrorMessage("입력한 정보의 회원이 없습니다.");
        } else if (error.message === 'Network Error') {
            alert("서버 연결이 실패되었습니다.");
        } else {
            alert("비밀번호 업데이트 중 오류가 발생했습니다.");
        }
    }

    const onClickFindPwd = () => {
        if (!checkName()) {
            alert("이름을 입력해주세요.");
            return;
        }
        if (!checkEmail()) {
            alert("이메일을 입력해주세요.");
            return;
        }
        const param = {
            method: 'POST',
            url: `${API.BACKEND}/v1/user/findPwd`,
            params: { userNm: userNm, userEmail: userEmail }
        };
        fn_axios(param, findPwdSuccessCallback, errorCallback);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onClickFindPwd();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        }
    }, [onClickFindPwd]);

    return (
        <Layout>
            <div className='contents find'>
                <h1>비밀번호 찾기</h1>
                <section className='default_find'>
                    <div className='form'>
                        <input type='text' value={userNm} onChange={handleIsNm} placeholder='이름' />
                        <input type='text' value={userEmail} onChange={handleIsEmail} placeholder='이메일' />
                    </div>
                    <button className='btn_type1 arrow' onClick={onClickFindPwd}>비밀번호 찾기</button>
                    <div className='find_pwd'>
                        {infoMessage && <p>{infoMessage}</p>}
                        {resetSuccess && <p>{successMessage}</p>}
                        {error404 && <p>{errorMessage}</p>}
                    </div>
                    <div className='mine' style={{ marginBottom: "10px" }}>
                        <a href='/user/login'>로그인 화면으로 이동</a>
                    </div>
                </section>
                <div className="tx_type02 pc">
                    <b>당신이 원하는 대회를 원하는 장소에서</b>당신의 대회를 알리고 싶다면, <b>WOORI GROUND!</b> WOOG ME! CONTACT ME!
                </div>
            </div>
        </Layout>
    )
}

export default FindPwd;
