import React from "react";
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';


import {fn_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";
import Slider from "../../layout/Slider.tsx";

function GymList() {

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [gymList, setGymList] = useState([]);
  const [cnt, setCnt] = useState(null);
console.log(queryParams.get('pageNum'));
  const [pageNum, setPageNum] = useState(queryParams.get('pageNum') == null || queryParams.get('pageNum') == "null" ? 1 : parseInt(queryParams.get('pageNum')));
  const [pageMaxNum, setPageMaxNum] = useState(0);
  const [pageCnt, setPageCnt] = useState(10);
  const [paging, setPaging] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(queryParams.get('searchKeyword') == null || queryParams.get('searchKeyword') == "null" ? "" : queryParams.get('searchKeyword'));

  const [sidoList, setSidoList] = useState([]);
  const [signguList, setSignguList] = useState([]);
  const [signguDataList, setSignguDataList] = useState([]);
  const [selectedSido, setSelectedSido] = useState(queryParams.get('spSignguCd') == null || queryParams.get('spSignguCd') == "null" ? '' : queryParams.get('spSignguCd'));
  const [selectedSigngu, setSelectedSigngu] = useState(queryParams.get('lareaAddrId') == null || queryParams.get('lareaAddrId') == "null" ? '' : queryParams.get('lareaAddrId'));

  const [gymTypeCdList, setGymTypeCdList] = useState([]);
  const [selectedGymTypeCd, setSelectedGymTypeCd] = useState(queryParams.get('gymTypeCd') == null || queryParams.get('gymTypeCd') == "null" ? '' : queryParams.get('gymTypeCd'));




  const getLareaAddr = () => {
    const param = {
        method: 'GET',
        url: `${API.BACKEND}/${API.VERSION}/lareaAddr/getLareaAddr`,
        params: null,
      };
      fn_axios(param, callBackGetLareaAddr, null);
  }
  
  const callBackGetLareaAddr = (res) => {  
    const resList = res.data.object;
    const tempList =resList.filter(item => (item.signguNm || '').trim() === "");

    setSignguDataList(resList);
    // setSidoList(tempList.map(item => item.spSignguNm));
    setSidoList(tempList);
    
    if(queryParams.get('spSignguCd')) {
      setSignguList(resList.filter( item => item.spSignguCd === queryParams.get('spSignguCd') && (item.signguNm || '').trim() !== "" ));
    }
  }

  const getCode = () => {
    const param = {
        method: 'GET',
        url: `${API.BACKEND}/${API.VERSION}/code/getCode`,
        params : {      
          codeMCd : "GYM_TYPE_CD" // 체육관 종류 코드
          , orderColum : "code_l_nm"
        }
      };
      fn_axios(param, callbackGetCode, null);
  }
  
  const callbackGetCode = (res) => {  
    console.log(res);
    setGymTypeCdList(res.data.object);
  }
  
  const handleSidoChange = (e) => {
    setSelectedSido(e.target.value);
    setSelectedSigngu("");
    setSignguList(signguDataList.filter( item => item.spSignguCd === e.target.value && (item.signguNm || '').trim() !== "" ));
    return;
  };


  const handleSignguChange  = (e) => {
    setSelectedSigngu(e.target.value);
    // onSelectedLareaAddr(e.target.value);
  }

  const handleGymTypeCd  = (e) => {
    setSelectedGymTypeCd(e.target.value);
    // onSelectedLareaAddr(e.target.value);
  }

  const getGymPaging = (num) => {
    // setBoard(null);
    const param = {
      method : 'GET',
      url : `${API.BACKEND}/${API.VERSION}/gym/getGymPaging`,
      params : {      pageNum : num
                    , searchKeyword : searchKeyword
                    , lareaAddrId : selectedSigngu
                    , spSignguCd : selectedSido
                    , gymTypeCd : selectedGymTypeCd
                }
      };
    fn_axios(param, callbackGetGymPaging,null);
  }

  const callbackGetGymPaging = (res) => {
    setGymList(res.data.object);
    setCnt(res.data.map.cnt);
      var tempInt  = parseInt(res.data.map.cnt%pageCnt);
      if(tempInt != 0 ) {
          tempInt = parseInt(res.data.map.cnt/pageCnt) + 1;
      } else {
          tempInt = parseInt(res.data.map.cnt/pageCnt);
      }
      settingPasing(tempInt);
  }

  const settingPasing = (maxNum) => {
      setPageMaxNum(parseInt(maxNum));
      // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
      var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2)  ;
      var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) +2;
      var tempList = [];

      if(maxNum != 0 ) {
          for (var i = start; i <= end; i++) {
              tempList.push(i);
          }
      }
      setPaging(tempList);
  }
  const onClickPaging = (e) => {
    setPageNum(parseInt(e.target.text));
    //getPointPage(e.target.text);

    return null; 
  }
  const onClickSearch = () => {
    if(pageNum == 1) {
      getGymPaging(pageNum);
    } else {
      setPageNum(1);
    }
    
  }

  const handleSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  useEffect(() => {
    getGymPaging(pageNum);
  }, [pageNum]); 

  useEffect(() => {
    if(sidoList == null || sidoList.length == 0 ) {
      getLareaAddr();
    }
    if(gymTypeCdList == null || gymTypeCdList.length == 0 ) {
      getCode();
    }
  }, []); 


return (
  <Layout>
        <div className="contents ground">
            <Slider></Slider>
            <h1 className="sub_title">그라운드 / 전체</h1>

            <section className="ground_list list_type1">
              {/* <!-- list /top search --> */}
              <div className="bar">
                <div className="tx_type03 odr2">전체 <b>{cnt == null ? "0" : cnt}</b>건</div>
                <div className="search_box odr1">
                  <select name="area1" id="sido1" value={selectedSido} onChange={handleSidoChange}>
                    <option value="">지역(시)</option>
                    {sidoList.map((item, index) => (
                      <option key={index} value={item.spSignguCd} >
                        {item.spSignguNm}
                      </option>
                    ))}
                  </select>
                  <select name="area2" id="signgu1" value={selectedSigngu} onChange={handleSignguChange}  >
                    <option value="" >지역(구)</option>
                    {signguList.map((item, index) => (
                      <option key={index} value={item.lareaAddrId} >
                        {item.signguNm}
                      </option>
                    ))}
                  </select>
                  <select style={{minWidth:"50px"}} name="gymType" id="gymType" value={selectedGymTypeCd} onChange={handleGymTypeCd}  >
                    <option value="" >종류</option>
                    {gymTypeCdList.map((item, index) => (
                      <option key={index} value={item.codeLCd} >
                        {item.codeLNm}
                      </option>
                    ))}
                  </select>
                  <input type="text" style={{minWidth:"90px"}} name="keyword"  value={searchKeyword} onChange={handleSearchKeyword} placeholder="키워드"/>
                  <button onClick={onClickSearch}>검색</button>
                </div>
                {/* <div className="list_date odr3">

                </div> */}
              </div>
              {/* <!-- //list top search --> */}
              {/* <!-- table pc ver--> */}
              <div className="table_type1 pc">
                <table>
                  <colgroup>
                    <col style={{width: "auto"}}/>
                    <col style={{width: "28%" }}/>
                    <col style={{width: "20%" }}/>
                    <col style={{width: "25%" }}/>
                  </colgroup>
                  <thead>
                    <th>이름</th>
                    <th>주소</th>
                    <th>연락처</th>
                    <th>그라운드 종류</th>
                  </thead>
                  <tbody>
                  {(gymList == null || gymList.length == 0) ?
                    <tr>
                      <td style={{"textAlign":"center"}} colSpan={4}> 검색된 결과가 없습니다.</td>
                    </tr>
                    : gymList.map((item, index) => (
                    <tr style={{cursor:"pointer"}} onClick={() => window.location.href = `/gym/gymDetail?gymId=${item.gymId}&pageNum=${pageNum}&lareaAddrId=${selectedSigngu}&spSignguCd=${selectedSido}&searchKeyword=${searchKeyword}&gymTypeCd=${selectedGymTypeCd}`}>
                      <td>{item.gymNm}</td>
                      <td>{item.dtlAddr}</td>
                      <td>{item.gymCadr == "" ? "-" : item.gymCadr}</td>
                      <td style={{textAlign: "center"}}>{item.gymTypeNm}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <!-- //table pc ver--> */}
              {/* <!-- table mo ver--> */}
              <div className="table_type1 mo">
                <table>
                  <colgroup>
                    <col style={{width: "auto"}}/>
                    <col style={{width: "28%" }}/>
                  </colgroup> 
                  <tbody>
                    {(gymList == null || gymList.length == 0) ?
                    <tr>
                      <td style={{"textAlign":"center"}} colSpan={2}> 검색된 결과가 없습니다.</td>
                    </tr>
                    : gymList.map((item, index) => (
                     <tr style={{cursor:"pointer"}} onClick={() => window.location.href = `/gym/gymDetail?gymId=${item.gymId}&pageNum=${pageNum}&lareaAddrId=${selectedSigngu}&spSignguCd=${selectedSido}&searchKeyword=${searchKeyword}&gymTypeCd=${selectedGymTypeCd}`}>
                      <td>{item.gymNm}</td>
                      <td>{item.lareaAddrNm}</td>
                    </tr> 
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <!-- //table mo ver--> */}
              {/* <!-- paging --> */}
              {gymList == null ? null : 
                    <div className="paging">
                        {paging.map((item, index) => (
                            <a href="#" key={item} onClick={onClickPaging} className={pageNum == item ? "on" :""}>{item}</a>
                        ))}
                    </div>
                }
              {/* <!-- //paging --> */}
            </section>
            </div>
      </Layout>
  );
}

export default GymList;
